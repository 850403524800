


import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  ref,
} from '@vue/composition-api';

import johaisetsuHanMastersApi from '@/apis/johaisetsu_han_master';
import { JohaisetsuCompanyExt } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { SearchState, LayerItems } from '@/models/johaisetsu/johaisetsuMap';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import RealtimeView from './components/RealtimeView/index.vue';
import ReportsView from './components/ReportsView/index.vue';
import { JohaisetsuHanWithCompany } from '@/models/apis/johaisetsu/johaisetsuHanMastersResponse';
import {
  waitForJohaisetsuMasters,
  normalizeKey,
  isMatchingKey,
} from '@/lib/johaisetsuHelper';
import {
  initSearchConds,
  initSearchState,
  initReportMapKinds,
  isValidSearchDateRange,
} from '@/components/Johaisetsu/JohaisetsuMap/utils';
import {
  VIEW_MODE_REALTIME,
  VIEW_MODE_REPORT,
  statusChoices,
} from '@/components/Johaisetsu/JohaisetsuMap/consts/johaisetsu_map';
import { johaisetsuAreas, ROLE_HONSHA } from '@/lib/johaisetsu/johaisetsuCommonUtil';
import useJohaisetsuCars, {
  provideJohaisetsuCars,
} from '@/components/Johaisetsu/JohaisetsuMap/composables/useJohaisetsuCars';
import {
  provideJohaisetsuReports,
  useJohaisetsuReports,
} from '@/components/Johaisetsu/JohaisetsuMap/composables/useJohaisetsuReports';
import { provideWaitSpinner } from '@/components/Johaisetsu/JohaisetsuMap/composables/useWaitSpinner';
import { dtFormat } from '@/lib/dateHelper';
import {
  ITEMS_PER_PAGE_DEFAULT,
  CURRENT_PAGE_DEFAULT,
} from '@/consts/report_print';

export default defineComponent({
  name: 'johaisetsu-map',
  setup() {
    const viewMode = ref<string>('');
    const searchState = reactive<SearchState>(initSearchState());
    const waitSpinnerState = provideWaitSpinner();

    const store = useStore();
    const userState = store.state.user;
    const isRealtimeMode = computed<boolean>(() => {
      return viewMode.value === VIEW_MODE_REALTIME;
    });
    const isReportMode = computed<boolean>(() => {
      return viewMode.value === VIEW_MODE_REPORT;
    });
    const johaisetsuComponentName = computed<string>(() => {
      return isRealtimeMode.value ? 'RealtimeView' : 'ReportsView';
    });
    const reportMapKinds = initReportMapKinds();
    const workType = computed<LayerItems[]>(() => {
      const reportMapKind = reportMapKinds.workType.find(x => x.type === searchState.johaisetsuType);
      return reportMapKind?.items ?? [];
    });
    const johaisetsuCompanyChoices = computed<JohaisetsuCompanyExt[]>(() => {
      const johaisetsuCompanies = searchState.johaisetsuCompanies.filter(e => {
        if (e.key.endsWith('driver')) return false;

        return searchState.johaisetsuHanMasters.some(han => normalizeKey(han.johaisetsu_role) === normalizeKey(e.key));
      });
      const getMatchingMasterName = (key: string, defaultVal: string) => {
        const matchingMaster = searchState.johaisetsuCompanyMaster.find(master =>
          master.johaisetsu_company_role.some(role => role.johaisetsu_role === key),
        );
        return matchingMaster ? `${matchingMaster.name} (${matchingMaster.alias_name ?? ''})` : defaultVal;
      };
      const filteredCompanies = searchState.searchConds.johaisetsuArea
        ? johaisetsuCompanies.filter(e => isMatchingKey(e.key, searchState.searchConds.johaisetsuArea))
        : johaisetsuCompanies;

      return filteredCompanies.map(e => ({
        ...e,
        nameDisp: getMatchingMasterName(e.key, e.val),
      }));
    });
    const johaisetsuHanChoices = computed<Record<string, string>>(() => {
      const hanMasters = filterHanMasters();
      return Object.fromEntries(hanMasters.map(e => [e.id, e.name]));
    });
    const handleAreaChange = () => {
      searchState.searchConds.johaisetsuCompanyId = null;
      handleCompanyChange();
    };
    const handleCompanyChange = () => {
      searchState.searchConds.johaisetsuHanName = null;
    };
    const doSearchReports = async() => {
      waitSpinnerState.showWaitSpinner = true;
      await searchReports();
      waitSpinnerState.showWaitSpinner = false;
    };
    const filterHanMasters = (): JohaisetsuHanWithCompany[] => {
      // 選択された管轄、清掃会社で清掃班を絞り込む
      // 絞り込みは清掃会社>管轄の優先順位とする
      if (searchState.searchConds.johaisetsuCompanyId) {
        const johaisetsuCompany = searchState.johaisetsuCompanies.find(
          e => e.id === searchState.searchConds.johaisetsuCompanyId,
        );
        if (!johaisetsuCompany) {
          return [];
        }
        return searchState.johaisetsuHanMasters.filter(han =>
          normalizeKey(han.johaisetsu_role) === normalizeKey(johaisetsuCompany.key));
      }
      if (searchState.searchConds.johaisetsuArea) {
        const johaisetsuArea = searchState.searchConds.johaisetsuArea;
        return searchState.johaisetsuHanMasters.filter(han =>
          normalizeKey(han.johaisetsu_role).endsWith(johaisetsuArea),
        );
      }
      return searchState.johaisetsuHanMasters.slice();
    };

    const { router } = useRoute();

    const isDateParamOk = computed<boolean>(() => {
      return isValidSearchDateRange(searchState.searchConds);
    });

    onMounted(async() => {
      await Promise.all([waitForUserAndMasters(), waitForJohaisetsuMasters()]);
      searchState.johaisetsuCompanyMaster = window.johaisetsuMaster.companyList;
      const johaisetsuArea = johaisetsuAreas.find(e => e.johaisetsuRole === userState.johaisetsu_role);
      const master = window.master;
      const johaisetsuCompanies = master.johaisetsuRoles.vals.filter(e => {
        if (johaisetsuArea) {
          return isMatchingKey(e.key, johaisetsuArea.name) && e.key !== johaisetsuArea.johaisetsuRole;
        } else {
          return normalizeKey(e.key) === normalizeKey(userState.johaisetsu_role);
        }
      });
      searchState.areaChoices = johaisetsuAreas.filter((e) => {
        if (userState.johaisetsu_role === ROLE_HONSHA) {
          return true;
        }
        if (johaisetsuArea) {
          return johaisetsuArea.name === e.name;
        }
        if (johaisetsuCompanies) {
          return johaisetsuCompanies.some(company => isMatchingKey(company.key, e.name));
        }
        return false;
      });
      searchState.johaisetsuCompanies = master.johaisetsuRoles.vals.filter((e) => {
        if (johaisetsuAreas.find(area => area.johaisetsuRole === e.key)) {
          return false;
        }
        if (e.key === ROLE_HONSHA) {
          return false;
        }
        if (userState.johaisetsu_role === ROLE_HONSHA) {
          return true;
        }
        return johaisetsuCompanies && johaisetsuCompanies.some(company =>
          normalizeKey(company.key) === normalizeKey(e.key));
      });
      searchState.johaisetsuCompanyAreaMap = searchState.johaisetsuCompanies
        .reduce((acc: Record<string, number[]>, e) => {
          const area = searchState.areaChoices.find(areaChoice => isMatchingKey(e.key, areaChoice.name));
          if (!area) return acc;
          if (!acc[area.name]) {
            acc[area.name] = [];
          }
          acc[area.name].push(e.id);
          return acc;
        }, {});
      const { data: hanResult } = await johaisetsuHanMastersApi.getDataBasedOnPermissions();
      searchState.johaisetsuHanMasters = hanResult;
      if (searchState.johaisetsuHanMasters.length === 1) {
        searchState.searchConds.johaisetsuHanName = searchState.johaisetsuHanMasters[0].name;
      }

      searchState.searchConds = initSearchConds(searchState.areaChoices, johaisetsuCompanyChoices.value);
      viewMode.value = VIEW_MODE_REALTIME;
    });

    const {
      filterCars,
      clearCars,
    } = (() => {
      const injectedState = provideJohaisetsuCars(searchState);
      return useJohaisetsuCars(injectedState);
    })();

    const {
      clearReports,
      searchReports,
    } = (() => {
      const reportState = provideJohaisetsuReports(searchState);
      return useJohaisetsuReports(reportState);
    })();

    const changeViewMode = async(mode: string) => {
      if (mode === viewMode.value) return;

      searchState.searchConds = initSearchConds(searchState.areaChoices, johaisetsuCompanyChoices.value);
      viewMode.value = mode;
      if (mode === VIEW_MODE_REALTIME) {
        clearReports();
      }
      if (mode === VIEW_MODE_REPORT) {
        clearCars();
      }
    };

    const onClickReportPrintAllButton = () => {
      // 管轄を選んだ時には、その管轄の除排雪協力会社のみを印刷対象にする
      const johaisetsuCompanyIds = [];
      if (searchState.searchConds.johaisetsuCompanyId) {
        johaisetsuCompanyIds.push(searchState.searchConds.johaisetsuCompanyId);
      } else if (searchState.searchConds.johaisetsuArea) {
        const companyIds = searchState.johaisetsuCompanyAreaMap[searchState.searchConds.johaisetsuArea];
        johaisetsuCompanyIds.push(...(companyIds ?? []));
      }

      const routeObj = {
        name: 'PrintJohaisetsuReportsAll',
        params: {
          dateFrom: dtFormat(searchState.searchConds.dateFrom, 'yyyy-mm-dd'),
          dateTo: dtFormat(searchState.searchConds.dateTo, 'yyyy-mm-dd'),
          johaisetsuCompanyIds: johaisetsuCompanyIds.join(',') || 'all',
          johaisetsuHanName: searchState.searchConds.johaisetsuHanName || 'all',
          johaisetsuType: searchState.johaisetsuType || 'all',
          workType: searchState.workType || 'all',
          currentPage: CURRENT_PAGE_DEFAULT.toString(),
          itemsPerPage: ITEMS_PER_PAGE_DEFAULT.toString(),
        },
      };
      const obj = router.resolve(routeObj);
      window.open(obj.href, '_blank');
    };

    return {
      searchState,
      waitSpinnerState,
      // const
      statusChoices,
      VIEW_MODE_REALTIME,
      VIEW_MODE_REPORT,
      // computed
      isRealtimeMode,
      isReportMode,
      isDateParamOk,
      johaisetsuHanChoices,
      johaisetsuCompanyChoices,
      johaisetsuComponentName,
      reportMapKinds,
      workType,
      // methods
      changeViewMode,
      handleAreaChange,
      handleCompanyChange,
      filterCars,
      doSearchReports,
      onClickReportPrintAllButton,
    };
  },
  components: {
    RealtimeView,
    ReportsView,
  },
});
