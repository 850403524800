














































import { defineComponent, PropType, computed } from '@vue/composition-api';
import { dtFormat } from '@/lib/dateHelper';
import { MiscInfoForCar, GeoItemLayerShow } from '@/models/johaisetsu/johaisetsuMap';
import dataLayerLegends from '@/components/lib/dataLayerLegend';

export default defineComponent({
  name: 'map-misc-info',
  props: {
    isRealtimeMode: {
      type: Boolean,
      default: false,
    },
    carsUpdatedAt: {
      type: Date,
    },
    workingCarsCount: {
      type: Number,
      default: 0,
    },
    movingCarsCount: {
      type: Number,
      default: 0,
    },
    stoppedCarsCount: {
      type: Number,
      default: 0,
    },
    carKind: {
      type: Object as PropType<MiscInfoForCar>,
    },
    geoItemLayer: {
      type: Object as PropType<GeoItemLayerShow>,
    },
  },
  setup(props) {
    const lineStyle = computed(() => {
      return props.carKind && props.carKind.color
        ? {
          borderTop: `0.5em solid ${props.carKind.color}`,
          fontSize: '0.8em',
          width: '5em',
          textAlign: 'center',
        }
        : {
          borderTop: '0.5em solid #18FFFF',
          fontSize: '0.8em',
          width: '5em',
          textAlign: 'center',
        };
    });
    const isShowSanpuNumType = computed<boolean>(() => {
      const { geoItemLayer } = props;
      return (
        geoItemLayer?.ensui_sanpu ||
        geoItemLayer?.shitsuen_sanpu ||
        geoItemLayer?.kokeizai_sanpu ||
        geoItemLayer?.kyokusho_sanpu ||
        geoItemLayer?.other_sanpu
      ) ?? false;
    });
    const johaisetsuTypeDisp = computed<string>(() => {
      const { geoItemLayer } = props;
      if (geoItemLayer?.sweeper_soukou) {
        return 'スイーパー作業';
      } else if (geoItemLayer?.other_soukou) {
        return 'その他作業';
      } else if (geoItemLayer?.kisei) {
        return '規制作業';
      } else if (geoItemLayer?.josetsu) {
        return '除雪作業';
      } else if (geoItemLayer?.haisetsu) {
        return '排雪作業';
      } else {
        return '';
      }
    });
    return {
      lineStyle,
      isShowSanpuNumType,
      johaisetsuTypeDisp,
      dtFormat,
    };
  },
  components: {
    ...dataLayerLegends,
  },
});
