

import {
  reactive,
  computed,
  defineComponent,
  onMounted,
} from '@vue/composition-api';
import { CompanyState, LayerItems } from '@/models/johaisetsu/companyHanCommon';
import { initCompanyState } from './utils';
import {
  ROLE_HONSHA,
  workTypes,
  johaisetsuAreas,
  getTaskForceInfos,
} from '@/lib/johaisetsu/johaisetsuCommonUtil';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import {
  waitForJohaisetsuMasters,
  normalizeKey,
  isMatchingKey,
  filterReportsByJohaisetsuType,
} from '@/lib/johaisetsuHelper';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import johaisetsuReportApi from '@/apis/johaisetsu_report';
import { JohaisetsuReportWithCarStatus } from '@/models/apis/johaisetsu/johaisetsuReportResponse';
import { getMaxAllowedDate } from '@/lib/dateHelper';
import MyCalendarWithTime from '@/components/lib/MyCalendarWithTime.vue';
import { getErrorMessages } from '@/lib/errMsgHelper';
import { AxiosError } from 'axios';

export default defineComponent({
  name: 'johaisetsu-company-han-management',
  components: { MyCalendarWithTime },
  setup() {
    const store = useStore();
    const userState = store.state.user;
    const state = reactive<CompanyState>(initCompanyState());
    const { route } = useRoute();
    const reportWorkType = computed<LayerItems[]>(() => {
      const reportMapKind = workTypes.find(x => x.type === state.johaisetsuType);
      return reportMapKind?.items ?? [];
    });
    const isDateParametersOk = computed<boolean>(() => {
      if (!state.selectedTaskForce) return false;
      if (!state.selectedTaskForce.start_date) return false;

      const searchDateFrom = state.searchConds.dateFrom;
      const searchDateTo = state.searchConds.dateTo;
      const taskForceDateFrom = state.selectedTaskForce.start_date;
      const taskForceDateEnd = state.selectedTaskForce.end_date ?? getMaxAllowedDate();
      if (searchDateFrom.getTime() > searchDateTo.getTime()) return false;
      if (searchDateFrom.getTime() < taskForceDateFrom.getTime()) return false;
      if (searchDateTo.getTime() > taskForceDateEnd.getTime()) return false;

      return true;
    });

    const fetchReports = async(): Promise<void> => {
      if (!state.selectedTaskForce) return;

      try {
        state.showWaitSpinner = true;
        const searchDateFrom = state.searchConds.dateFrom;
        const searchDateTo = state.searchConds.dateTo;
        const taskForceDateFrom = state.selectedTaskForce.start_date;
        const taskForceDateEnd = state.selectedTaskForce.end_date ?? getMaxAllowedDate();
        if (!taskForceDateFrom) return;

        const dateFrom: Date = searchDateFrom.getTime() < taskForceDateFrom.getTime()
          ? taskForceDateFrom
          : searchDateFrom;
        const dateTo: Date = taskForceDateEnd.getTime() < searchDateTo.getTime()
          ? taskForceDateEnd
          : searchDateTo;
        if (dateFrom > dateTo) {
          hideAndResetReports();
          return;
        }
        // 日付範囲が変わらない場合、DB検索を控える
        if (
          formatDateTime(state.dataDateFrom) === formatDateTime(dateFrom) &&
          formatDateTime(state.dataDateTo) === formatDateTime(dateTo)
        ) return;
        const johaisetsuCompanyIds = state.johaisetsuCompanies
          ? state.johaisetsuCompanies.map(company => company.id)
          : [];
        state.dataDateFrom = dateFrom;
        state.dataDateTo = dateTo;
        const reqParams = {
          ts_from: dateFrom,
          ts_to: dateTo,
          johaisetsu_company_ids: johaisetsuCompanyIds,
          johaisetsu_han_name: '',
          johaisetsu_type: '',
          work_type: '',
        };
        const { data: johaisetsuReports } = await johaisetsuReportApi.index(reqParams);
        state.reports = filterReportsByJohaisetsuType(johaisetsuReports);
        state.groupedReports = groupReports(state.reports);
      } catch (error) {
        state.errorBody = getErrorMessages(error as AxiosError).join(', ');
        state.showErrorModal = true;
      } finally {
        state.showWaitSpinner = false;
      }
    };

    const hideAndResetReports = (): void => {
      state.reports = [];
      state.groupedReports = null;
      state.dataDateFrom = null;
      state.dataDateTo = null;
    };

    const groupReports = (reports: JohaisetsuReportWithCarStatus[]): Record<string, JohaisetsuReportWithCarStatus[]> => {
      return reports.reduce<Record<string, JohaisetsuReportWithCarStatus[]>>((acc, report) => {
        const reportRole = state.johaisetsuRoles.find(role => role.id === report.johaisetsu_company_id);
        const content = `${report.johaisetsu_type}(${report.work_type})`;
        const area = reportRole
          ? state.areaChoices.find(areaChoice => isMatchingKey(reportRole.key, areaChoice.name))
          : null;
        const key = `${content}: ${area?.dispName ?? ''} ${report.johaisetsu_han_name}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(report);
        return acc;
      }, {});
    };

    const doSearch = async(): Promise<void> => {
      await fetchReports();
      let filteredReports = [...state.reports];
      if (state.johaisetsuType) {
        filteredReports = filteredReports.filter(report => report.johaisetsu_type === state.johaisetsuType);
      }
      if (state.workType) {
        filteredReports = filteredReports.filter(report => report.work_type === state.workType);
      }
      if (state.searchConds.johaisetsuArea) {
        const areaKeys = state.johaisetsuCompanyAreaMap[state.searchConds.johaisetsuArea];
        const companyMap = window.johaisetsuMaster.companyList;
        filteredReports = filteredReports.filter(report => {
          const company = companyMap.find(company => company.id === report.johaisetsu_company_id);
          if (!company) return false;

          const johaisetsuRoles = company.johaisetsu_company_role.map(e => e.johaisetsu_role);
          if (!johaisetsuRoles) return false;

          return areaKeys.some(key => johaisetsuRoles.includes(key));
        });
      }
      state.groupedReports = groupReports(filteredReports);
    };

    const onSelectedTaskForceChange = (): void => {
      const taskForceId = parseInt(state.taskForceId);
      if (isNaN(taskForceId)) {
        hideAndResetReports();
        return;
      }
      const selectedTaskForce = state.taskForces.find(taskForce => taskForce.id === taskForceId);
      if (!selectedTaskForce) {
        hideAndResetReports();
        return;
      }
      state.selectedTaskForce = selectedTaskForce;
      state.searchConds.dateFrom = selectedTaskForce.start_date ?? state.searchConds.dateFrom;
      state.searchConds.dateTo = selectedTaskForce.end_date ?? new Date();
      doSearch();
    };

    const formatDateTime = (date: Date | null): string => {
      if (!date) return '';

      const d = new Date(date);
      return d.toLocaleString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
    };

    const getStatus = (report: JohaisetsuReportWithCarStatus): string => {
      if (report.base_arrival_ts) {
        return '作業完了';
      } else if (report.start_ts && !report.end_ts) {
        return report.car_status_disp ?? '作業中';
      } else {
        return '移動中';
      }
    };

    const getStatusClass = (report: JohaisetsuReportWithCarStatus): Record<string, boolean> => {
      const status = getStatus(report);
      return {
        'status-complete': status === '作業完了',
        'status-in-progress': status === '作業中',
        'status-moving': status === '移動中',
      };
    };

    onMounted(async() => {
      state.showWaitSpinner = true;
      state.taskForceId = route.value.params.taskForceId;
      await Promise.all([
        waitForUserAndMasters(),
        waitForJohaisetsuMasters(),
      ]);
      const { taskForces, selectedTaskForce } = getTaskForceInfos(state.taskForceId);
      state.taskForces = taskForces;
      state.selectedTaskForce = selectedTaskForce;
      await doSearch();
      state.showWaitSpinner = false;
      state.johaisetsuRoles = window.master.johaisetsuRoles.vals;
      const johaisetsuArea = johaisetsuAreas.find(e => e.johaisetsuRole === userState.johaisetsu_role);
      const johaisetsuCompanies = window.master.johaisetsuRoles.vals.filter(e => {
        if (johaisetsuArea) {
          return isMatchingKey(e.key, johaisetsuArea.name) && e.key !== johaisetsuArea.johaisetsuRole;
        } else {
          return normalizeKey(e.key) === normalizeKey(userState.johaisetsu_role);
        }
      });
      state.areaChoices = johaisetsuAreas.filter(e => {
        if (userState.johaisetsu_role === ROLE_HONSHA) {
          return true;
        }
        if (johaisetsuArea) {
          return johaisetsuArea.name === e.name;
        }
        return johaisetsuCompanies.some(company => isMatchingKey(company.key, e.name));
      });
      state.johaisetsuCompanies = window.master.johaisetsuRoles.vals.filter(e => {
        if (johaisetsuAreas.find(area => area.johaisetsuRole === e.key)) {
          return false;
        }
        if (e.key === ROLE_HONSHA) {
          return false;
        }
        if (userState.johaisetsu_role === ROLE_HONSHA) {
          return true;
        }
        return johaisetsuCompanies.some(company => normalizeKey(company.key) === normalizeKey(e.key));
      });
      state.johaisetsuCompanyAreaMap = state.johaisetsuCompanies.reduce((acc, e) => {
        const area = state.areaChoices.find(areaChoice => isMatchingKey(e.key, areaChoice.name));
        if (area) {
          if (!acc[area.name]) {
            acc[area.name] = [];
          }
          acc[area.name].push(e.key);
        }
        return acc;
      }, {} as Record<string, string[]>);
      state.searchConds.dateFrom = selectedTaskForce.start_date ?? state.searchConds.dateFrom;
      state.searchConds.dateTo = selectedTaskForce.end_date ?? new Date();
      await doSearch();
      state.showWaitSpinner = false;
    });

    return {
      state,
      workTypes,
      reportWorkType,
      isDateParametersOk,
      doSearch,
      formatDateTime,
      getStatus,
      getStatusClass,
      onSelectedTaskForceChange,
    };
  },
});
