


import {
  defineComponent,
  PropType,
  reactive,
  watch,
} from '@vue/composition-api';
import {
  Location,
} from '@/models/index';
import { JohaisetsuCarExt } from '@/models/johaisetsu/spJohaisetsuReport';
import { CreateReportState } from '@/models/spSettouReport';
import { initCreateReportState } from '@/components/Sp/SettouReport/SettouReportCommon/CreateReport/utils/index';

export default defineComponent({
  props: {
    johaisetsuCar: {
      type: Object as PropType<JohaisetsuCarExt>,
      required: true,
    },
    currentLocation: {
      type: Object as PropType<Location>,
    },
    selectedJohaisetsuType: {
      type: String,
      required: true,
    },
    isRequesting: {
      type: Boolean,
      required: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    showCompleteCreateModal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive<CreateReportState>(initCreateReportState());
    const tryRevertCreateReport = (): void => {
      state.showConfirmCancelModal = false;
      emit('tryRevertCreateReportOnCommon');
    };
    const showErrorModal = (message: string): void => {
      state.errorModalMsg = message;
      state.showErrorModal = true;
    };
    const showConfirmCancelModal = (): void => {
      state.showConfirmCancelModal = true;
    };
    const tryShowConfirmCreateModal = (): void => {
      state.showConfirmCreateModal = true;
    };
    const tryShowConfirmCancelModal = (): void => {
      state.showConfirmCancelModal = true;
    };
    const closeCompleteCreateModal = (): void => {
      state.showCompleteCreateModal = false;
      emit('tryRevertCreateReportOnCommon');
    };
    const saveReport = (): void => {
      state.showConfirmCreateModal = false;
      emit('saveReport');
    };

    watch(() => props.errorMessage, (newVal) => {
      if (newVal) {
        showErrorModal(newVal);
      }
    });
    watch(() => props.showCompleteCreateModal, (newVal) => {
      if (newVal) {
        state.showCompleteCreateModal = true;
      }
    });

    return {
      state,
      tryRevertCreateReport,
      showConfirmCancelModal,
      tryShowConfirmCreateModal,
      tryShowConfirmCancelModal,
      closeCompleteCreateModal,
      saveReport,
    };
  },
});
