import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { EmptyResponse } from '@/models/index';
import {
  JohaisetsuMtxsCreateJohaisetsuMtxsParams,
  JohaisetsuMtxGetParams,
  JohaisetsuMtx,
} from '@/models/apis/johaisetsu/johaisetsuMtxsRequest';

export default {
  createJohaisetsuMTXs(params: JohaisetsuMtxsCreateJohaisetsuMtxsParams): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_mtxs'), params);
  },
  getJohaisetsuMtxs(params: JohaisetsuMtxGetParams): Promise<AxiosResponse<Record<number, JohaisetsuMtx[]>>> {
    return axios.get(utils.getApiUrl(`/johaisetsu_mtxs`), {
      params: params || {},
    });
  },
};
