


import {
  defineComponent,
  computed,
  onBeforeUnmount,
  reactive,
  PropType,
} from '@vue/composition-api';
import { SpSettouReportState } from '@/models/spSettouReport';
import PhotoTaker from '@/components/lib/PhotoTaker.vue';
import settouManagerApi from '@/apis/johaisetsu_settou_onsite_management_report';
import { Location } from '@/models/index';
import { getResizedImage } from '@/lib/imageHelper';
import { JohaisetsuCarExt } from '@/models/johaisetsu/spJohaisetsuReport';
import {
  initSpSettouReportState,
  MAX_SAVABLE_PHOTOS_COUNT,
  SETTOU_ONSITE_MANAGEMENT_REPORT_FILE_NAME_PREFIX_FOR_DOWNLOAD,
} from '@/components/Sp/SettouReport/SettouReportCommon/CreateReport/utils/index';
import { SpSettouOnsiteManagementReportExt } from '@/models/apis/settou/settouOnsiteManagementReportsRequest';
import CreateReportCommon from '@/components/Sp/SettouReport/SettouReportCommon/CreateReport/index.vue';

export default defineComponent({
  props: {
    johaisetsuCar: {
      type: Object as PropType<JohaisetsuCarExt>,
      required: true,
    },
    currentLocation: {
      type: Object as PropType<Location>,
    },
    selectedJohaisetsuType: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<SpSettouReportState>(initSpSettouReportState());

    const canAddPendingPhoto = computed<boolean>(() => {
      if (!state.settouOnsiteManagementReport.imageCandidates) return false;

      return state.settouOnsiteManagementReport.imageCandidates.length < MAX_SAVABLE_PHOTOS_COUNT;
    });
    const onImageFileAttach = async(evt: Event): Promise<void> => {
      const fileList = (evt.target as HTMLInputElement).files;
      if (!fileList || fileList.length === 0) return;

      const file = fileList[0];
      attachImageFile(file);
    };
    const attachImageFile = async(file: File): Promise<void> => {
      if (!state.settouOnsiteManagementReport.imageCandidates) return;
      state.errorMessage = null;
      if (!canAddPendingPhoto.value) {
        state.errorMessage = '添付画像の最大数は' + MAX_SAVABLE_PHOTOS_COUNT + '枚です。';
        return;
      }

      // リサイズ後は確実に10MBを切るのでサイズ確認はしなくてよい.
      const resizedImage = await getResizedImage(file, { maxWidth: 1000, maxHeight: 1000 });
      if (!resizedImage) return;

      state.settouOnsiteManagementReport.imageCandidates.push({
        file: resizedImage,
        src: URL.createObjectURL(resizedImage),
        isSaved: false,
      });
    };
    const removeImageCandidate = (idx: number): void => {
      if (!state.settouOnsiteManagementReport.imageCandidates) return;

      URL.revokeObjectURL(state.settouOnsiteManagementReport.imageCandidates[idx].src);
      state.settouOnsiteManagementReport.imageCandidates.splice(idx, 1);
    };
    const releaseImageObjectURLs = (): void => {
      if (state.settouOnsiteManagementReport.imageCandidates) {
        state.settouOnsiteManagementReport.imageCandidates.forEach(e => URL.revokeObjectURL(e.src));
      }
    };
    const getParamsForReq = (): SpSettouOnsiteManagementReportExt => {
      const { lat, lon } = props.currentLocation || { lat: 0, lon: 0 };
      const johaisetsuReport = props.johaisetsuCar.report;
      const reqObj: SpSettouOnsiteManagementReportExt = {
        lat,
        lon,
        work_type: johaisetsuReport.workType ?? '',
        johaisetsu_han_name: johaisetsuReport.johaisetsuHanName,
        road_name_disp: johaisetsuReport.roadNameDisp,
        work_area: johaisetsuReport.workArea,
        manager_name: johaisetsuReport.managerName,
        phone_number: johaisetsuReport.phoneNumber,
        work_progress: state.settouOnsiteManagementReport.work_progress,
        images: state.settouOnsiteManagementReport.imageCandidates?.map(e => e.file) || [],
      };
      return reqObj;
    };
    const saveSettouManagerReport = async(): Promise<void> => {
      state.isRequesting = true;
      state.errorMessage = null;
      try {
        const reqObj = getParamsForReq();
        if (!reqObj.work_type) {
          state.errorMessage = '報告書の作成に失敗しました。再度操作を行ってください。';
          return;
        }

        await settouManagerApi.create(reqObj);
        state.isRequesting = false;
        releaseImageObjectURLs();
        state.showCompleteCreateModal = true;
        emit('showCompleteCreateModal');
      } catch (e) {
        state.isRequesting = false;
        state.errorMessage = '報告書の作成に失敗しました。再度操作を行ってください。';
      }
    };
    const tryRevertCreateReport = (): void => {
      emit('tryRevertCreateReportOnCommon');
    };

    onBeforeUnmount(() => {
      releaseImageObjectURLs();
    });
    return {
      state,
      SETTOU_ONSITE_MANAGEMENT_REPORT_FILE_NAME_PREFIX_FOR_DOWNLOAD,
      canAddPendingPhoto,
      onImageFileAttach,
      attachImageFile,
      removeImageCandidate,
      saveSettouManagerReport,
      tryRevertCreateReport,
    };
  },
  components: {
    PhotoTaker,
    CreateReportCommon,
  },
});
