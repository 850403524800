import {
  WeatherObservatoryDataPoint,
  WeatherObservatoryWithDataPoints,
} from '@/models/apis/weatherObservatory/weatherObservatoryResponse';

export interface ChartModalState {
  selectedData: string;
  timeLabels: string[];
  chartColors: string[];
}

export const dataLabelMap: Record<string, string> = {
  'air_temperature': '気温',
  'road_temperature': '路温',
  'humidity': '湿度',
  'ten_min_accumulated_rainfall': '降雨量',
  'snow_depth': '積雪量',
};
export const dataStepMap: Record<string, number> = {
  'air_temperature': 1,
  'road_temperature': 1,
  'humidity': 10,
  'ten_min_accumulated_rainfall': 5,
  'snow_depth': 5,
};
export const dataUnitMap: Record<string, string> = {
  'air_temperature': '℃',
  'road_temperature': '℃',
  'humidity': '%',
  'ten_min_accumulated_rainfall': 'mm',
  'snow_depth': 'cm',
};
export const CHART_LINE_COLOR_NUM = 512;
export const CHART_LINE_COLOR_CREATE_STEP = 31;
export const CHART_LINE_GREEN_ADJUST_STEP = 85;
export const CHART_LINE_BLUE_ADJUST_STEP = 170;
export const CHART_LINE_COLOR_SHOW_STEP = 6;
export const CHART_PERCENT_MAX = 100;
export const CHART_RAINFALL_SNOWDEPTH_AMOUNT_MAX = 30;
export const CHART_RAINFALL_SNOWDEPTH_AMOUNT_MAX_STEP = 5;
export const CHART_TEMPERATURE_STEP = 1;
export const CHART_MIN_VALUE_ZERO = 0;

export const getInitChartModalState = (): ChartModalState => {
  const labelKeys = Object.keys(dataLabelMap);
  return {
    selectedData: labelKeys[0],
    timeLabels: [],
    chartColors: [],
  };
};
export const isDisabledData = (observatories: WeatherObservatoryWithDataPoints[], dataName: string): boolean => {
  const param = dataName as keyof WeatherObservatoryDataPoint;
  return observatories.filter(observatory => {
    return observatory.weather_observatory_data_points.some(data => data[param] !== null);
  }).length === 0;
};
export const getInitSelectedData = (observatories: WeatherObservatoryWithDataPoints[]): string => {
  const labelKeys = Object.keys(dataLabelMap);
  return labelKeys.find(key => !isDisabledData(observatories, key)) ?? labelKeys[0];
};
