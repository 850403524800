import johaisetsuSettouPatrolApi from '@/apis/johaisetsu_settou_patrol';
import { JohaisetsuSettouPatrol } from '@/models/apis/johaisetsu/settouPatrol/settouPatrol/johaisetsuSettouPatrolResponse';
import {
  JohaisetsuSettouPatrolStartUpdateParams,
  JohaisetsuSettouPatrolEndUpdateParams,
  JohaisetsuSettouPatrolBaseArrivalUpdateParams,
} from '@/models/apis/johaisetsu/settouPatrol/settouPatrol/johaisetsuSettouPatrolRequest';

export const updateJohaisetsuStart = async(johaisetsuCarId: number, startTs: Date): Promise<JohaisetsuSettouPatrol | null> => {
  const reqObj: JohaisetsuSettouPatrolStartUpdateParams = {
    johaisetsu_car_id: johaisetsuCarId,
    start_ts: startTs,
  };
  const { data } = await johaisetsuSettouPatrolApi.updateStart(reqObj);
  return data;
};
export const updateJohaisetsuEnd = async(johaisetsuCarId: number, endTs: Date): Promise<JohaisetsuSettouPatrol | null> => {
  const reqObj: JohaisetsuSettouPatrolEndUpdateParams = {
    johaisetsu_car_id: johaisetsuCarId,
    end_ts: endTs,
  };
  const { data } = await johaisetsuSettouPatrolApi.updateEnd(reqObj);
  return data;
};
export const updateBaseArrival = async(johaisetsuCarId: number): Promise<JohaisetsuSettouPatrol | null> => {
  const reqObj: JohaisetsuSettouPatrolBaseArrivalUpdateParams = {
    johaisetsu_car_id: johaisetsuCarId,
    base_arrival_ts: new Date(),
  };
  const { data } = await johaisetsuSettouPatrolApi.updateBaseArrival(reqObj);
  return data;
};
