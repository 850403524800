


import {
  defineComponent,
} from '@vue/composition-api';
import SettouReportCommon from '@/components/Sp/SettouReport/SettouReportCommon/index.vue';
import { ABILITY_SETTOU_PATROL_REPORT_INPUT } from '@/consts/ability';
import { JOHAISETSU_TYPE_GROUP_SETTOU_PATROL } from '@/consts/johaisetsu_car';
import { provideSettouReport } from '@/components/Sp/SettouReport/composables/useSettouReport';
import CreateSettouPatrolReport from '@/components/Sp/SettouReport/SettouPatrolReport/CreateSettouPatrolReport/index.vue';
import johaisetsuSettouPatrolApi from '@/apis/johaisetsu_settou_patrol';
import { JohaisetsuSettouPatrolDetailApi } from '@/models/johaisetsu/johaisetsuCommon';
import {
  updateJohaisetsuStart,
  updateJohaisetsuEnd,
  updateBaseArrival,
} from '@/lib/johaisetsu/johaisetsuSettouPatrolUtil';

export default defineComponent({
  setup() {
    const state = provideSettouReport({
      ability: ABILITY_SETTOU_PATROL_REPORT_INPUT,
      johaisetsuTypeGroup: JOHAISETSU_TYPE_GROUP_SETTOU_PATROL,
      johaisetsuTypeGroupName: '雪凍パトロール報告書',
      johaisetsuWorkTypeName: 'パトロール',
    });

    const detailApis: JohaisetsuSettouPatrolDetailApi = {
      create: johaisetsuSettouPatrolApi.create,
      update: johaisetsuSettouPatrolApi.update,
      getCurrent: johaisetsuSettouPatrolApi.getCurrent,
      updateJohaisetsuStart: updateJohaisetsuStart,
      updateJohaisetsuEnd: updateJohaisetsuEnd,
      updateBaseArrival: updateBaseArrival,
    };

    return {
      state,
      detailApis,
      createReportComponent: CreateSettouPatrolReport,
    };
  },
  components: { SettouReportCommon },
});
