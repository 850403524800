


import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';

import { useStore } from '@/hooks/useStore';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { isRoleDriver, isRoleHonsha, isRoleKyoku } from '@/lib/roleHelper';
import {
  isJohaisetsuRoleHonsha,
  isJohaisetsuRoleKyoku,
  isJohaisetsuRoleDriver,
  shouldForceJohaisetsuOnly,
} from '@/lib/johaisetsuRoleHelper';
import { filterAbilitiesByJohaisetsuRole,
  filterAbilitiesByCleaningRole,
  filterJohaisetsuAbilities,
  filterCleaningAbilities,
} from '@/lib/abilityHelper';
import { filterDefaultPagesByJohaisetsuRole, filterDefaultPagesByCleaningRole, filterDefaultPagesByAbilityIds } from '@/lib/defaultPageHelper';
import { downloadBlob } from '@/lib/downloadHelper';
import { dtFormat } from '@/lib/dateHelper';

import { Lov } from '@/models/apis/master/masterResponse';
import {
  AdminUserUploadCsvResponse,
  AdminUser } from '@/models/apis/user/adminUserResponse';
import { AdminGroup2 } from '@/models/apis/group2/adminGroup2Response';
import { AdminGroup3 } from '@/models/apis/group3/adminGroup3Response';
import { AdminAbility } from '@/models/apis/ability/adminAbilityResponse';

import abilityApi from '@/apis/ability';
import group2Api from '@/apis/group2';
import group3Api from '@/apis/group3';
import userApi from '@/apis/user';
import cleaningHanMastersApi from '@/apis/cleaning_han_master';
import johaisetsuHanMastersApi from '@/apis/johaisetsu_han_master';
import { GroupInfo } from '@/models/apis/user/userResponse';
import {
  ABILITY_MESSAGE_BOARD,
  ABILITY_CLEANING_MAP,
  ABILITY_CLEANING_REPORT,
} from '@/consts/ability';
import {
  USER_JOHAISETSU_ROLE,
  USER_IS_JOHAISETSU_ONLY,
  USER_CLEANING_ROLE,
  USER_CLEANING_HAN,
  USER_JOHAISETSU_HAN,
  USER_IS_CLEANING_ONLY,
  USER_ABILITY_JOHAISETSU_MESSAGE_BOARD,
  USER_ABILITY_CLEANING_MAP,
  USER_ABILITY_JOHAISETSU,
  USER_ABILITY_CLEANING,
  DEFAULT_PAGE_PC_TOP,
} from '@/consts/users';
import { CleaningHan } from 'src/models/apis/cleaningHanMasters/cleaningHanMastersResponse';
import { JohaisetsuHan } from 'src/models/apis/johaisetsu/johaisetsuHanMastersResponse';
import boardEntryApi from '@/apis/board_entry';
import { BoardMaster } from '@/models/apis/settouMessageBoard/settouMessageBoardResponse';
import { normalizeKey } from '@/lib/johaisetsuHelper';

interface SearchParams {
  group2_id: number | null;
  group3_id: number | null;
  username_or_display_name: string | null;
  role: string | null;
}

interface AdminUserEdit {
  id: number;
  group2_id?: number | null;
  group_id?: number | null;
  username: string;
  display_name: string;
  role: string | null;
  password?: string;
  password_confirmation?: string;
  johaisetsu_role: string | null;
  cleaning_role: string | null;
  cleaning_han: CleaningHan[];
  cleaning_han_id: number | null;
  johaisetsu_han: JohaisetsuHan[];
  johaisetsu_han_id: number | null;
  johaisetsu_ability_ids: number[];
  cleaning_ability_ids: number[];
  abilities?: AdminAbility[];
  default_page?: string | null;
  group_info?: GroupInfo;
  isNew?: boolean;
  is_johaisetsu_only: boolean;
  is_cleaning_only: boolean;
}

interface CsvResult extends AdminUserUploadCsvResponse {
  hasErrors: boolean;
}
interface Pagination {
  currentPage: number;
  itemsPerPage: number;
}
interface AdminUserState {
  groups2: AdminGroup2[];
  groups3: AdminGroup3[];
  roles: Lov[];
  johaisetsuRoles: Lov[];
  cleaningRoles: Lov[];
  cleaningHans: CleaningHan[];
  johaisetsuHans: JohaisetsuHan[];
  defaultPagesOrig: Lov[];
  abilitiesOrig: AdminAbility[];
  cleaningHanMastersOrig: CleaningHan[];
  johaisetsuHanMastersOrig: JohaisetsuHan[];
  boardMasters: BoardMaster[];

  roleMap: Record<string, Lov>;
  johaisetsuRoleMap: Record<string, Lov>;
  cleaningRoleMap: Record<string, Lov>;
  cleaningHanMap: Record<string, Lov>;
  defaultPageMap: Record<string, Lov>;

  search: SearchParams;
  usersOrig: AdminUser[];
  users: AdminUser[];

  edittingUser: AdminUserEdit;
  edittingUserOrig: AdminUserEdit;
  deletingUser: AdminUser;
  uploadingCsv: File;
  csvResult: CsvResult;

  showJohaisetsuSettings: boolean;
  showCleaningSettings: boolean;
  showEditModalJohaisetsuSettings: boolean;
  showEditModalCleaningSettings: boolean;
  showEditModal: boolean;
  showEditClearConfirmModal: boolean;
  showEditPasswordModal: boolean;
  showEditPasswordClearConfirmModal: boolean;
  showDeleteModal: boolean;
  showConfirmCsvUploadModal: boolean;
  showCsvUploadCompleteModal: boolean;
  showErrorModal: boolean;
  errorBody: string;

  isDownloading: boolean;
  isUploading: boolean;

  pager: Pagination;
}

export default defineComponent({
  name: 'admin-users',

  setup() {
    const initSearchParams = (): SearchParams => {
      return {
        group2_id: null,
        group3_id: null,
        username_or_display_name: null,
        role: null,
      };
    };
    const state = reactive<AdminUserState>({
      groups2: [],
      groups3: [],
      roles: [],
      johaisetsuRoles: [],
      cleaningRoles: [],
      cleaningHans: [],
      johaisetsuHans: [],
      defaultPagesOrig: [],
      abilitiesOrig: [],
      cleaningHanMastersOrig: [],
      johaisetsuHanMastersOrig: [],
      boardMasters: [],

      roleMap: {},
      johaisetsuRoleMap: {},
      cleaningRoleMap: {},
      cleaningHanMap: {},
      defaultPageMap: {},

      search: initSearchParams(),
      usersOrig: [],
      users: [],

      edittingUser: {} as AdminUserEdit,
      edittingUserOrig: {} as AdminUserEdit,
      deletingUser: {} as AdminUser,
      uploadingCsv: {} as File,
      csvResult: {} as CsvResult,

      showJohaisetsuSettings: false,
      showCleaningSettings: false,
      showEditModalJohaisetsuSettings: false,
      showEditModalCleaningSettings: false,
      showEditModal: false,
      showEditClearConfirmModal: false,
      showEditPasswordModal: false,
      showEditPasswordClearConfirmModal: false,
      showDeleteModal: false,
      showConfirmCsvUploadModal: false,
      showCsvUploadCompleteModal: false,
      showErrorModal: false,
      errorBody: '',

      isDownloading: false,
      isUploading: false,

      pager: {
        currentPage: 1,
        itemsPerPage: 50,
      },
    });
    const store = useStore();
    const userState = store.state.user;
    const isSuperAdmin = computed<boolean>(() => {
      return userState.has_role_super_admin;
    });
    const isJohaisetsuAvailable = computed<boolean>(() => {
      return userState.settings.is_johaisetsu_available;
    });
    const canUseCleaning = computed<boolean>(() => {
      return userState.settings.can_use_cleaning;
    });
    const paginatedUsers = computed<AdminUser[]>(() => {
      const nextPageIdx = state.pager.currentPage * state.pager.itemsPerPage;
      const minIdx = nextPageIdx - state.pager.itemsPerPage;
      return state.users.filter((e, i) => {
        return i >= minIdx && i < nextPageIdx;
      });
    });
    const shouldShowEditModal = computed<boolean>(() => {
      if (state.showErrorModal) { return false; }
      if (state.showEditClearConfirmModal) { return false; }
      return state.showEditModal;
    });
    const shouldShowEditPasswordModal = computed<boolean>(() => {
      if (state.showErrorModal) { return false; }
      if (state.showEditPasswordClearConfirmModal) { return false; }
      return state.showEditPasswordModal;
    });
    const shouldShowDeleteModal = computed<boolean>(() => {
      if (state.showErrorModal) { return false; }
      return state.showDeleteModal;
    });
    const isReadyToSave = computed<boolean>(() => {
      return !checkInputs();
    });
    const isUserRoleInBoardMasters = computed<boolean>(() => {
      if (!state.edittingUser.johaisetsu_role) return false;
      if (isRoleHonsha(state.edittingUser.johaisetsu_role)) return true;
      if (isRoleKyoku(state.edittingUser.johaisetsu_role)) return true;

      const userRole = normalizeKey(state.edittingUser.johaisetsu_role);
      return state.boardMasters.some(e => e.owner_company_name === userRole);
    });
    const isReadyToUpdatePassword = computed<boolean>(() => {
      return !checkPasswordInputs();
    });
    const passwordComfirmationErrorMsg = computed<string | null>(() => {
      if (state.edittingUser.password !== state.edittingUser.password_confirmation) {
        return 'パスワードが一致しません。';
      }
      return null;
    });
    const abilities = computed<AdminAbility[]>(() => {
      // johaisetsu_roleによって、選択可能なabilityが変わる.
      return filterAbilitiesByJohaisetsuRole(
        state.abilitiesOrig,
        state.edittingUser.johaisetsu_role,
      );
    });
    const cleaningAbilities = computed<AdminAbility[]>(() => {
      // cleaning_roleによって、選択可能なabilityが変わる.
      return filterAbilitiesByCleaningRole(
        state.abilitiesOrig,
        state.edittingUser.cleaning_role,
      );
    });
    const defaultPages = computed<Lov[]>(() => {
      // johaisetsu_roleによって、選択可能なdefault_pageが変わる.
      let defaultJohaisetsuPages = filterDefaultPagesByJohaisetsuRole(
        state.defaultPagesOrig,
        state.edittingUser.johaisetsu_role,
      );
      if (isJohaisetsuRoleDriver(state.edittingUser.johaisetsu_role)) {
        // johaisetsu_roleがドライバーの場合は、ability_idsによって選択可能なdefault_pageが変わる.
        defaultJohaisetsuPages = filterDefaultPagesByAbilityIds(
          defaultJohaisetsuPages,
          state.edittingUser.johaisetsu_ability_ids,
        );
      }

      // cleaning_roleによって、選択可能なdefault_pageが変わる.
      let defaultCleaningPages = filterDefaultPagesByCleaningRole(
        state.defaultPagesOrig,
        state.edittingUser.cleaning_role,
      );
      if (isRoleDriver(state.edittingUser.cleaning_role)) {
        // cleaning_roleによって、選択可能なdefault_pageが変わるがドライバーの場合は、ability_idsによって選択可能なdefault_pageが変わる.
        defaultCleaningPages = filterDefaultPagesByAbilityIds(
          defaultCleaningPages,
          state.edittingUser.cleaning_ability_ids,
        );
      }

      const defaultPageSet = new Set([...defaultJohaisetsuPages, ...defaultCleaningPages]);
      return [...defaultPageSet];
    });
    const disabledEditItems = computed<Array<string>>(() => {
      // 設定値によって排他になる項目を返す
      const items: Array<string> = [];
      if (state.edittingUser.is_johaisetsu_only) {
        items.push(...[USER_CLEANING_ROLE, USER_ABILITY_JOHAISETSU_MESSAGE_BOARD]);
      }
      if (isRoleDriver(state.edittingUser.johaisetsu_role)) {
        items.push(USER_IS_JOHAISETSU_ONLY);
      } else {
        items.push(USER_JOHAISETSU_HAN);
      }
      if (!isUserRoleInBoardMasters.value) {
        state.edittingUser.johaisetsu_ability_ids = state.edittingUser.johaisetsu_ability_ids.filter(id => {
          return id !== ABILITY_MESSAGE_BOARD;
        });
        items.push(USER_ABILITY_JOHAISETSU_MESSAGE_BOARD);
      }
      if (state.edittingUser.is_cleaning_only) {
        items.push(...[USER_JOHAISETSU_ROLE, USER_ABILITY_CLEANING_MAP]);
      }
      if (isRoleDriver(state.edittingUser.cleaning_role)) {
        items.push(USER_IS_CLEANING_ONLY);
      } else {
        items.push(USER_CLEANING_HAN);
      }
      return items;
    });

    onMounted(async() => {
      await waitForUserAndMasters();
      await getMasters();
      await getUsers();
    });

    const getMasters = async() => {
      const p1 = group2Api.adminIndex();
      const p2 = group3Api.adminIndex();
      const p3 = abilityApi.adminIndex();
      const p4 = cleaningHanMastersApi.index();
      const p5 = johaisetsuHanMastersApi.index();
      const p6 = boardEntryApi.getBoardMasters();
      const [
        { data: groups2 },
        { data: groups3 },
        { data: abilities },
        { data: cleaningHanMasters },
        { data: johaisetsuHanMasters },
        { data: boardMasters },
      ] = await Promise.all([p1, p2, p3, p4, p5, p6]);

      const lovs = window.master.lovs;
      let roles: Lov[] = lovs.role.vals;
      if (!isSuperAdmin) {
        roles = roles.filter(e => parseInt(e.key) >= 100);
      }
      const johaisetsuRoles = window.master.johaisetsuRoles.vals;
      const defaultPages = lovs.default_page.vals;

      const roleMap = lovs.role.map;
      const johaisetsuRoleMap = window.master.johaisetsuRoles.map;
      const defaultPageMap = lovs.default_page.map;

      const cleaningRoleMap = lovs.cleaning_role.map;
      const cleaningRoles = lovs.cleaning_role.vals;

      state.groups2 = groups2;
      state.groups3 = groups3;
      state.roles = roles;
      state.johaisetsuRoles = johaisetsuRoles;
      state.defaultPagesOrig = defaultPages;
      state.abilitiesOrig = abilities;
      state.cleaningHanMastersOrig = cleaningHanMasters;
      state.johaisetsuHanMastersOrig = johaisetsuHanMasters;
      state.boardMasters = boardMasters;

      state.cleaningRoles = cleaningRoles;

      state.roleMap = roleMap;
      state.johaisetsuRoleMap = johaisetsuRoleMap;
      state.defaultPageMap = defaultPageMap;

      state.cleaningRoleMap = cleaningRoleMap;
    };
    const getUsers = async() => {
      state.usersOrig = [];
      state.users = [];
      const { data } = await userApi.adminIndex();
      if (!data || data.length === 0) { return; }
      state.usersOrig = data;
      filterUsers();
    };
    const filterUsers = () => {
      const qGroup2Id = state.search.group2_id;
      const qGroup3Id = state.search.group3_id;
      const qUsernameOrDisplayName = state.search.username_or_display_name;
      const qRole = state.search.role;
      state.users = state.usersOrig.filter(e => {
        if (qGroup2Id) {
          if (e.group_info.g2id !== qGroup2Id) { return false; }
        }
        if (qGroup3Id) {
          if (e.group_info.g3id !== qGroup3Id) { return false; }
        }
        if (qUsernameOrDisplayName) {
          if (
            (e.username.indexOf(qUsernameOrDisplayName) === -1) &&
            (e.display_name.indexOf(qUsernameOrDisplayName) === -1)
          ) { return false; }
        }
        if (qRole) {
          if (parseInt(e.role) !== parseInt(qRole)) { return false; }
        }

        return true;
      });
    };
    const getGroups3 = (group2Id: number) => {
      if (group2Id == null) { return state.groups3; }
      return state.groups3.filter(e => {
        return e.parent_id === group2Id;
      });
    };
    const doSearch = () => {
      state.pager.currentPage = 1;
      filterUsers();
    };
    const getAbilityIdsForDisp = (abilities: AdminAbility[], type: string) => {
      if (type === 'cleaning') {
        return filterCleaningAbilities(abilities).map(e => e.id).join(', ');
      } else {
        return filterJohaisetsuAbilities(abilities).map(e => e.id).join(', ');
      }
    };
    const getJohaisetsuHanForDisp = (johaisetsu_han: JohaisetsuHan[]): string => {
      return johaisetsu_han ? johaisetsu_han[0]?.name : '';
    };
    const getCleaningHanForDisp = (cleaning_han: CleaningHan[]): string => {
      return cleaning_han ? cleaning_han[0]?.name : '';
    };
    const resetJohaisetsuSettings = () => {
      if (state.showEditModalJohaisetsuSettings) { return; }
      state.edittingUser.johaisetsu_role = null;
      state.edittingUser.johaisetsu_ability_ids = [];
      state.edittingUser.is_johaisetsu_only = false;
      onJohaisetsuRoleChange();
    };
    const resetCleaningSettings = () => {
      if (state.showEditModalCleaningSettings) { return; }
      state.edittingUser.cleaning_role = null;
      state.edittingUser.cleaning_ability_ids = [];
      state.edittingUser.is_cleaning_only = false;
      onCleaningRoleChange();
    };
    const onJohaisetsuRoleChange = () => {
      state.edittingUser.johaisetsu_ability_ids = [];
      if (isJohaisetsuRoleHonsha(state.edittingUser.johaisetsu_role)) {
        // 本社のデフォルト: 進捗表、作業状況地図
        const defaultJohaisetsuAbilities = [23, 32];
        state.edittingUser.johaisetsu_ability_ids = abilities.value
          .filter(e => defaultJohaisetsuAbilities.includes(e.id))
          .map(e => e.id);
        state.edittingUser.is_johaisetsu_only = false;
      } else if (isJohaisetsuRoleKyoku(state.edittingUser.johaisetsu_role)) {
        // 局のデフォルト: 大体全部
        const defaultJohaisetsuAbilities = [11, 12, 21, 22, 23, 31, 32, 41, 42];
        state.edittingUser.johaisetsu_ability_ids = abilities.value
          .filter(e => defaultJohaisetsuAbilities.includes(e.id))
          .map(e => e.id);
        state.edittingUser.is_johaisetsu_only = false;
      } else if (isJohaisetsuRoleDriver(state.edittingUser.johaisetsu_role)) {
        // ドライバーの場合は自分で選ばせるのでデフォを設定しない
        state.edittingUser.is_johaisetsu_only = false;
      } else if (state.edittingUser.johaisetsu_role) {
        // 災害支援会社を選択した場合は、強制的にONになる
        const defaultJohaisetsuAbilities = [ABILITY_MESSAGE_BOARD];
        state.edittingUser.johaisetsu_ability_ids = abilities.value
          .filter(e => defaultJohaisetsuAbilities.includes(e.id))
          .map(e => e.id);
        state.edittingUser.is_johaisetsu_only = shouldForceJohaisetsuOnly(state.edittingUser.johaisetsu_role);
      }

      if (!disabledEditItems.value.includes(USER_JOHAISETSU_HAN)) {
        state.edittingUser.johaisetsu_han_id = null;
      }
      resetJohaisetsuHanSelectBox();

      // johaisetsu_roleによって、選択可能なdefault_pageが変わる.
      let defaultPage = defaultPages.value.find(e => {
        return e.key === state.edittingUser.default_page;
      });
      if (!defaultPage) {
        defaultPage = defaultPages.value[0];
        state.edittingUser.default_page = defaultPage ? defaultPage.key : '';
      }
      onIsJohaisetsuOnlyChange();
    };
    const onIsJohaisetsuOnlyChange = () => {
      if (!state.edittingUser.is_johaisetsu_only) {
        return;
      }
      if (state.showEditModalCleaningSettings) {
        state.showEditModalCleaningSettings = false;
        resetCleaningSettings();
      }
      if (!state.edittingUser.johaisetsu_ability_ids.includes(ABILITY_MESSAGE_BOARD) &&
        isUserRoleInBoardMasters.value
      ) {
        state.edittingUser.johaisetsu_ability_ids.push(ABILITY_MESSAGE_BOARD);
      }
    };
    const onCleaningRoleChange = () => {
      // 清掃
      state.edittingUser.cleaning_ability_ids = [];
      const defaultCleaningAbilities = isRoleDriver(state.edittingUser.cleaning_role)
        ? [ABILITY_CLEANING_REPORT]
        : [ABILITY_CLEANING_MAP];
      state.edittingUser.cleaning_ability_ids = cleaningAbilities.value
        .filter(e => defaultCleaningAbilities.includes(e.id))
        .map(e => e.id);

      resetCleaningHanSelectBox();
      if (!disabledEditItems.value.includes(USER_CLEANING_HAN)) {
        state.edittingUser.cleaning_han_id = null;
      }

      let defaultPage = defaultPages.value.find(e => {
        return e.key === state.edittingUser.default_page;
      });
      if (isRoleDriver(state.edittingUser.cleaning_role)) {
        defaultPage = defaultPages.value.filter(e => e.key.indexOf('_sp') !== -1)[0];
      } else if (!defaultPage) {
        defaultPage = defaultPages.value.find(e => e.key === DEFAULT_PAGE_PC_TOP);
      }
      state.edittingUser.default_page = defaultPage ? defaultPage.key : '';
    };
    const onIsCleaningOnlyChange = () => {
      if (!state.edittingUser.is_cleaning_only) {
        return;
      }
      if (state.showEditModalJohaisetsuSettings) {
        state.showEditModalJohaisetsuSettings = false;
        resetJohaisetsuSettings();
      }
      if (!state.edittingUser.cleaning_ability_ids.includes(ABILITY_CLEANING_MAP)) {
        state.edittingUser.cleaning_ability_ids.push(ABILITY_CLEANING_MAP);
      }
    };

    const resetCleaningHanSelectBox = () => {
      state.cleaningHans = state.cleaningHanMastersOrig.filter(e => e.cleaning_role === state.edittingUser.cleaning_role);
    };
    const resetJohaisetsuHanSelectBox = () => {
      state.johaisetsuHans = state.johaisetsuHanMastersOrig.filter(e => e.johaisetsu_role === state.edittingUser.johaisetsu_role);
    };
    const onAbilityChange = () => {
      if (!isJohaisetsuRoleDriver(state.edittingUser.johaisetsu_role)) { return; }

      // johaisetsu_roleがドライバーの場合は、ability_idsによって選択可能なdefault_pageが変わる.
      let defaultPage = defaultPages.value.find(e => {
        return e.key === state.edittingUser.default_page;
      });
      if (!defaultPage || defaultPage.key.indexOf('_sp') === -1) {
        defaultPage = defaultPages.value.filter(e => e.key.indexOf('_sp') !== -1)[0];
        state.edittingUser.default_page = defaultPage ? defaultPage.key : '';
      }
    };
    const onCleaningAbilityChange = () => {
      if (!isRoleDriver(state.edittingUser.cleaning_role)) { return; }

      let defaultPage = defaultPages.value.find(e => {
        return e.key === state.edittingUser.default_page;
      });
      if (!defaultPage || defaultPage.key.indexOf('_sp') === -1) {
        defaultPage = defaultPages.value.filter(e => e.key.indexOf('_sp') !== -1)[0];
        state.edittingUser.default_page = defaultPage ? defaultPage.key : '';
      }
    };

    const openCreateModal = () => {
      const defaultPage = state.defaultPagesOrig[0] ? state.defaultPagesOrig[0].key : '';
      const obj: AdminUserEdit = {
        id: 0,
        group2_id: null,
        group_id: null, // group_id
        username: '',
        display_name: '',
        role: null,
        default_page: defaultPage,
        password: '',
        password_confirmation: '',
        johaisetsu_role: null,
        cleaning_role: null,
        cleaning_han: [],
        cleaning_han_id: null,
        johaisetsu_han: [],
        johaisetsu_han_id: null,
        johaisetsu_ability_ids: [],
        cleaning_ability_ids: [],
        isNew: true,
        is_johaisetsu_only: false,
        is_cleaning_only: false,
      };
      state.showEditModalJohaisetsuSettings = false;
      state.showEditModalCleaningSettings = false;
      openEditModal(obj);
    };
    const openUpdateModal = (item: AdminUserEdit) => {
      const defaultPage = state.defaultPagesOrig[0] ? state.defaultPagesOrig[0].key : '';
      const obj: AdminUserEdit = {
        id: item.id,
        group2_id: item.group_info?.g2id,
        group_id: item.group_info?.g3id, // group_id
        username: item.username,
        display_name: item.display_name,
        role: item.role,
        default_page: item.default_page || defaultPage,
        johaisetsu_role: item.johaisetsu_role,
        cleaning_role: item.cleaning_role,
        cleaning_han: item.cleaning_han,
        cleaning_han_id: item.cleaning_han ? item.cleaning_han[0]?.id : null,
        johaisetsu_han: item.johaisetsu_han,
        johaisetsu_han_id: item.johaisetsu_han ? item.johaisetsu_han[0]?.id : null,
        johaisetsu_ability_ids: filterJohaisetsuAbilities(item.abilities || []).map(e => e.id),
        cleaning_ability_ids: filterCleaningAbilities(item.abilities || []).map(e => e.id),
        is_johaisetsu_only: item.is_johaisetsu_only,
        is_cleaning_only: item.is_cleaning_only,
      };
      state.showEditModalJohaisetsuSettings = !!item.johaisetsu_role;
      state.showEditModalCleaningSettings = !!item.cleaning_role;
      openEditModal(obj);
    };
    const openEditModal = (adminUserEditObj: AdminUserEdit) => {
      prepareOrigValues(adminUserEditObj);
      state.edittingUser = adminUserEditObj;
      state.showEditModal = true;

      resetCleaningHanSelectBox();
      resetJohaisetsuHanSelectBox();
    };
    const tryCloseEditModal = () => {
      if (isEdited()) {
        state.showEditClearConfirmModal = true;
      } else {
        state.showEditModal = false;
      }
    };
    const closeEditModal = () => {
      state.edittingUser = {} as AdminUserEdit;
      state.showEditClearConfirmModal = false;
      state.showEditModal = false;
    };
    const saveUser = async() => {
      try {
        const abilityIds = [...state.edittingUser.johaisetsu_ability_ids, ...state.edittingUser.cleaning_ability_ids];
        if (state.edittingUser.isNew) {
          await userApi.adminCreate({
            ...state.edittingUser,
            ability_ids: abilityIds,
          });
        } else {
          await userApi.adminUpdate(state.edittingUser.id, {
            group_id: state.edittingUser.group_id,
            username: state.edittingUser.username,
            display_name: state.edittingUser.display_name,
            role: state.edittingUser.role,
            default_page: state.edittingUser.default_page,
            johaisetsu_role: state.edittingUser.johaisetsu_role,
            ability_ids: abilityIds,
            is_johaisetsu_only: state.edittingUser.is_johaisetsu_only,
            cleaning_role: state.edittingUser.cleaning_role,
            cleaning_han_id: state.edittingUser.cleaning_han_id,
            is_cleaning_only: state.edittingUser.is_cleaning_only,
            johaisetsu_han_id: state.edittingUser.johaisetsu_han_id,
          });
        }
        closeEditModal();
        await getUsers();
      } catch (e) {
        console.error('error', e);
        state.errorBody = '保存に失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
      }
    };
    const openEditPasswordModal = (item: AdminUserEdit) => {
      const obj = {
        id: item.id,
        password: '',
        password_confirmation: '',
      } as AdminUserEdit;
      prepareOrigValues(obj);
      state.edittingUser = obj;
      state.showEditPasswordModal = true;
    };
    const tryCloseEditPasswordModal = () => {
      if (isEdited()) {
        state.showEditPasswordClearConfirmModal = true;
      } else {
        state.showEditPasswordModal = false;
      }
    };
    const closeEditPasswordModal = () => {
      state.edittingUser = {} as AdminUserEdit;
      state.showEditPasswordClearConfirmModal = false;
      state.showEditPasswordModal = false;
    };
    const updatePassword = async() => {
      try {
        await userApi.adminPasswordUpdate(state.edittingUser.id, {
          password: state.edittingUser.password || '',
          password_confirmation: state.edittingUser.password_confirmation || '',
        });
        closeEditPasswordModal();
        await getUsers();
      } catch (e) {
        console.error('error', e);
        state.errorBody = 'パスワードの変更に失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
      }
    };
    const openDeleteModal = (item: AdminUser) => {
      state.deletingUser = item;
      state.showDeleteModal = true;
    };
    const deleteUser = async() => {
      try {
        await userApi.adminDestroy(state.deletingUser.id);
        state.deletingUser = {} as AdminUser;
        state.showDeleteModal = false;
        await getUsers();
      } catch (e) {
        console.error('error', e);
        state.errorBody = '削除に失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
      }
    };
    const prepareOrigValues = (obj: AdminUserEdit): void => {
      state.edittingUserOrig = { ...obj };
    };
    const isEdited = () => {
      const obj = state.edittingUser;
      const objOrig = state.edittingUserOrig;

      if (obj.group2_id !== objOrig.group2_id) { return true; }
      if (obj.group_id !== objOrig.group_id) { return true; }
      if (obj.username !== objOrig.username) { return true; }
      if (obj.display_name !== objOrig.display_name) { return true; }
      if (obj.role !== objOrig.role) { return true; }
      if (obj.default_page !== objOrig.default_page) { return true; }
      if (obj.password !== objOrig.password) { return true; }
      if (obj.johaisetsu_role !== objOrig.johaisetsu_role) { return true; }
      if (obj.johaisetsu_ability_ids !== objOrig.johaisetsu_ability_ids) { return true; }
      if (obj.cleaning_ability_ids !== objOrig.cleaning_ability_ids) { return true; }
      if (obj.cleaning_role !== objOrig.cleaning_role) { return true; }
      if (obj.cleaning_han_id !== objOrig.cleaning_han_id) { return true; }

      return false;
    };
    const checkInputs = () => {
      const obj = state.edittingUser;
      if (
        !obj.group2_id ||
        !obj.group_id ||
        !obj.username ||
        !obj.display_name ||
        !obj.role ||
        !obj.default_page
      ) {
        return false;
      }
      if (state.showEditModalJohaisetsuSettings && !obj.johaisetsu_role) {
        return false;
      }
      if (
        !Number.isInteger(obj.group2_id) ||
        !Number.isInteger(obj.group_id) ||
        !Number.isInteger(parseInt(obj.role))
      ) {
        return false;
      }
      if (obj.username.match(/^\s*$/) || obj.display_name.match(/^\s*$/)) {
        return false;
      }
      if (obj.isNew && !checkPasswordInputs()) {
        return false;
      }
      if (isRoleDriver(obj.cleaning_role) && !obj.cleaning_han_id) {
        return false;
      }
      if (isRoleDriver(obj.johaisetsu_role) && !obj.johaisetsu_han_id) {
        return false;
      }

      return true;
    };
    const checkPasswordInputs = () => {
      const obj = state.edittingUser;
      if (!obj.password || obj.password.length < 8) { return false; }
      if (!isValidPasswordString(obj.password)) { return false; }
      if (obj.password !== obj.password_confirmation) { return false; }

      return true;
    };
    const isValidPasswordString = (password: string) => {
      const regAlphaLower = /[a-z]/;
      const regAlphaUpper = /[A-Z]/;
      const regNumber = /[0-9]/;
      const regSymbol = /[!@#$%^&*(){}[\]:;"'<>,.?/~`_+\-=|\\ ]/;
      // 3種類以上の文字種を含めることを強制する.
      let numStrKinds = 0;
      if (password.match(regAlphaLower)) { numStrKinds++; }
      if (password.match(regAlphaUpper)) { numStrKinds++; }
      if (password.match(regNumber)) { numStrKinds++; }
      if (password.match(regSymbol)) { numStrKinds++; }
      return numStrKinds >= 3;
    };
    const downloadCSV = async() => {
      state.isDownloading = true;
      try {
        const { data } = await userApi.adminDownloadCSV(state.search);
        const timestamp = dtFormat(new Date(), 'yyyymmdd_HHMMSS');
        const filename = `ユーザー一覧_${timestamp}`;
        downloadBlob(data, filename);
        state.isDownloading = false;
      } catch (e) {
        console.error('error', e);
        state.errorBody = 'CSVダウンロードに失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
        state.isDownloading = false;
      }
    };
    const openConfirmCsvUploadModal = (evt: Event) => {
      const eventTarget = evt.target as HTMLInputElement;
      if (!eventTarget || !eventTarget.files) {
        return;
      }
      const file = eventTarget.files[0];
      state.uploadingCsv = file;
      state.showConfirmCsvUploadModal = true;
    };
    const closeConfirmCsvUploadModal = () => {
      state.uploadingCsv = {} as File;
      state.showConfirmCsvUploadModal = false;
    };
    const uploadCSV = async() => {
      state.isUploading = true;
      try {
        const formData = new FormData();
        formData.append('file', state.uploadingCsv, state.uploadingCsv.name);
        const { data } = await userApi.adminUploadCSV(formData);
        state.csvResult = {
          ...data,
          hasErrors: data.failures.length > 0,
        };
        state.showCsvUploadCompleteModal = true;
        closeConfirmCsvUploadModal();
        state.isUploading = false;
        await getUsers();
      } catch (e) {
        console.error('error', e);
        state.errorBody = 'CSVアップロードに失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
        closeConfirmCsvUploadModal();
        state.isUploading = false;
      }
    };
    const closeCsvUploadCompleteModal = () => {
      state.csvResult = {} as CsvResult;
      state.showCsvUploadCompleteModal = false;
    };
    return {
      ...toRefs(state),
      // consts
      USER_JOHAISETSU_ROLE,
      USER_IS_JOHAISETSU_ONLY,
      USER_CLEANING_ROLE,
      USER_CLEANING_HAN,
      USER_JOHAISETSU_HAN,
      USER_IS_CLEANING_ONLY,
      USER_ABILITY_JOHAISETSU,
      USER_ABILITY_CLEANING,
      // computed
      isSuperAdmin,
      isJohaisetsuAvailable,
      canUseCleaning,
      paginatedUsers,
      shouldShowEditModal,
      shouldShowEditPasswordModal,
      shouldShowDeleteModal,
      isReadyToSave,
      isReadyToUpdatePassword,
      passwordComfirmationErrorMsg,
      abilities,
      cleaningAbilities,
      defaultPages,
      disabledEditItems,
      // methods
      getMasters,
      getUsers,
      filterUsers,
      getGroups3,
      doSearch,
      getAbilityIdsForDisp,
      getCleaningHanForDisp,
      getJohaisetsuHanForDisp,
      resetJohaisetsuSettings,
      resetCleaningSettings,
      onJohaisetsuRoleChange,
      onIsJohaisetsuOnlyChange,
      onCleaningRoleChange,
      onIsCleaningOnlyChange,
      onAbilityChange,
      onCleaningAbilityChange,
      openCreateModal,
      openUpdateModal,
      tryCloseEditModal,
      closeEditModal,
      saveUser,
      openEditPasswordModal,
      tryCloseEditPasswordModal,
      closeEditPasswordModal,
      updatePassword,
      openDeleteModal,
      deleteUser,
      prepareOrigValues,
      isEdited,
      checkInputs,
      checkPasswordInputs,
      isValidPasswordString,
      downloadCSV,
      openConfirmCsvUploadModal,
      closeConfirmCsvUploadModal,
      uploadCSV,
      closeCsvUploadCompleteModal,
    };
  },
});
