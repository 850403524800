import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { SettouPatrolReportsParams, SpSettouPatrolReportExt } from '@/models/apis/settou/settouPatrolReportsRequest';
import { SettouPatrolReport, SettouPatrolReports } from '@/models/apis/settou/settouPatrolReportsResponse';

export default {
  index(params: SettouPatrolReportsParams): Promise<AxiosResponse<SettouPatrolReports>> {
    return axios.get(utils.getApiUrl('/johaisetsu_settou_patrol_reports'), {
      params: params || {},
    });
  },
  show(id: number): Promise<AxiosResponse<SettouPatrolReport>> {
    return axios.get(utils.getApiUrl(`/johaisetsu_settou_patrol_reports/${id}`));
  },
  create(params: SpSettouPatrolReportExt): Promise<AxiosResponse<SettouPatrolReport>> {
    const formData = utils.transformFormData(params);
    return axios.post(utils.getApiUrl('/johaisetsu_settou_patrol_reports'), formData);
  },
  downloadCSV(params: SettouPatrolReportsParams): Promise<AxiosResponse<Blob>> {
    return axios.get(utils.getApiUrl(`/johaisetsu_settou_patrol_reports/csv`), {
      params: params,
      responseType: 'blob',
    });
  },
};
