


import {
  defineComponent,
  reactive,
  PropType,
} from '@vue/composition-api';
import useSettouReport from '@/components/Sp/SettouReport/composables/useSettouReport';
import { JohaisetsuCarExt } from '@/models/johaisetsu/spJohaisetsuReport';
import { JohaisetsuSettouPatrolUpdateParamsCommon } from 'src/models/apis/johaisetsu/settouPatrol/johaisetsuSettouPatrolRequestCommon';

interface State {
  showConfirmWorkSituationModal: boolean;
  isRequesting: boolean;
  managerName?: string | null;
  phoneNumber?: string | null;
}

export default defineComponent({
  props: {
    selectedJohaisetsuType: {
      type: String,
      required: true,
    },
    johaisetsuCar: {
      type: Object as PropType<JohaisetsuCarExt>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      johaisetsuTypes,
      isShowJohaisetsuTypeSelect,
    } = useSettouReport({ selectedJohaisetsuType: props.selectedJohaisetsuType });
    const state = reactive<State>({
      showConfirmWorkSituationModal: false,
      isRequesting: false,
      managerName: props.johaisetsuCar.report.managerName,
      phoneNumber: props.johaisetsuCar.report.phoneNumber,
    });
    const displayJohaisetsuHan = props.johaisetsuCar.report.johaisetsuHanName;
    const tryShowConfirmWorkSituationModal = () => {
      state.showConfirmWorkSituationModal = true;
    };
    const tryRevertEditWorkSituation = () => {
      emit('tryRevertEditWorkSituationOnCommon');
    };
    const saveWorkSituation = () => {
      if (!props.johaisetsuCar.id || !props.johaisetsuCar.report.id) {
        emit('showErrorOnCommon', 'レポートIDの取得に失敗しました。');
        return;
      }
      const johaisetsuSettouPatrolUpdateParams: JohaisetsuSettouPatrolUpdateParamsCommon = {
        johaisetsu_car_id: props.johaisetsuCar.id,
        manager_name: state.managerName,
        phone_number: state.phoneNumber,
      };
      emit('saveWorkSituationOnCommon', johaisetsuSettouPatrolUpdateParams);
      state.showConfirmWorkSituationModal = false;
    };

    return {
      state,
      johaisetsuTypes,
      isShowJohaisetsuTypeSelect,
      displayJohaisetsuHan,
      saveWorkSituation,
      tryShowConfirmWorkSituationModal,
      tryRevertEditWorkSituation,
    };
  },
});
