import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  JohaisetsuSettouOnsiteManagementCreateParams,
  JohaisetsuSettouOnsiteManagementUpdateParams,
  JohaisetsuSettouOnsiteManagementStartUpdateParams,
  JohaisetsuSettouOnsiteManagementEndUpdateParams,
  JohaisetsuSettouOnsiteManagementBaseArrivalUpdateParams,
  JohaisetsuSettouOnsiteManagementGetCurrentParams,
} from '@/models/apis/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsuSettouOnsiteManagementRequest';
import {
  JohaisetsuSettouOnsiteManagementResponse,
} from '@/models/apis/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsuSettouOnsiteManagementResponse';

export default {
  create(
    params: JohaisetsuSettouOnsiteManagementCreateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouOnsiteManagementResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_settou_onsite_management'), params);
  },
  update(
    params: JohaisetsuSettouOnsiteManagementUpdateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouOnsiteManagementResponse>> {
    return axios.post(utils.getApiUrl('/update_johaisetsu_settou_onsite_management'), params);
  },
  updateStart(
    params: JohaisetsuSettouOnsiteManagementStartUpdateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouOnsiteManagementResponse>> {
    return axios.post(utils.getApiUrl('/update_johaisetsu_settou_onsite_management_start'), params);
  },
  updateEnd(
    params: JohaisetsuSettouOnsiteManagementEndUpdateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouOnsiteManagementResponse>> {
    return axios.post(utils.getApiUrl('/update_johaisetsu_settou_onsite_management_end'), params);
  },
  updateBaseArrival(
    params: JohaisetsuSettouOnsiteManagementBaseArrivalUpdateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouOnsiteManagementResponse>> {
    return axios.post(utils.getApiUrl('/update_johaisetsu_settou_onsite_management_base_arrival'), params);
  },
  getCurrent(
    params: JohaisetsuSettouOnsiteManagementGetCurrentParams,
  ): Promise<AxiosResponse<JohaisetsuSettouOnsiteManagementResponse>> {
    return axios.get(utils.getApiUrl('/johaisetsu_settou_onsite_management/current'), { params });
  },
};
