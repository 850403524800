


import {
  defineComponent,
  reactive,
} from '@vue/composition-api';
import useSettouReport from '@/components/Sp/SettouReport/composables/useSettouReport';

interface State {
  showConfirmStartWorkingModal: boolean;
  showConfirmStopWorkingModal: boolean;
}

export default defineComponent({
  props: {
    selectedJohaisetsuType: {
      type: String,
      required: true,
    },
    isRequesting: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      showConfirmStartWorkingModal: false,
      showConfirmStopWorkingModal: false,
    });
    const {
      johaisetsuTypeNameDisp,
      johaisetsuWorkTypeName,
      isShowJohaisetsuTypeSelect,
      restartWorkingLabel,
      stopWorkingLabel,
    } = useSettouReport({ selectedJohaisetsuType: props.selectedJohaisetsuType });
    const editWorkSituation = (): void => {
      emit('editWorkSituationOnCommon');
    };
    const tryShowConfirmStartWorkingModal = (): void => {
      state.showConfirmStartWorkingModal = true;
    };
    const startWorking = (): void => {
      state.showConfirmStartWorkingModal = false;
      emit('startWorkingOnCommon');
    };
    const showConfirmStopWorkingModal = (): void => {
      state.showConfirmStopWorkingModal = true;
    };
    const stopWorking = (): void => {
      state.showConfirmStopWorkingModal = false;
      emit('stopWorkingOnCommon');
    };

    return {
      state,
      johaisetsuTypeNameDisp,
      johaisetsuWorkTypeName,
      isShowJohaisetsuTypeSelect,
      restartWorkingLabel,
      stopWorkingLabel,
      editWorkSituation,
      tryShowConfirmStartWorkingModal,
      startWorking,
      showConfirmStopWorkingModal,
      stopWorking,
    };
  },
});
