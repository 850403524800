import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  JohaisetsuReportCreateParams,
  JohaisetsuReportJohaisetsuStartUpdateParams,
  JohaisetsuReportJohaisetsuEndUpdateParams,
  JohaisetsuReportBaseArrivalUpdateParams,
  JohaisetsuReportContentAndRoadParams,
  JohaisetsuReportIndexParams,
  JohaisetsuReportGetCurrentParams,
  JohaisetsuReportGetByCarIdsParams,
} from '@/models/apis/johaisetsu/johaisetsuReportRequest';
import {
  JohaisetsuReportIndexResponse,
  JohaisetsuReportCreateOrUpdateResponse,
} from '@/models/apis/johaisetsu/johaisetsuReportResponse';

export default {
  index(params: JohaisetsuReportIndexParams): Promise<AxiosResponse<JohaisetsuReportIndexResponse>> {
    return axios.get(utils.getApiUrl('/johaisetsu_reports'), {
      params: params || {},
    });
  },
  createJohaisetsuReport(
    params: JohaisetsuReportCreateParams): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_reports'), params);
  },
  updateJohaisetsuStart(
    params: JohaisetsuReportJohaisetsuStartUpdateParams): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl('/update_johaisetsu_start'), params);
  },
  updateJohaisetsuEnd(
    params: JohaisetsuReportJohaisetsuEndUpdateParams): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl('/update_johaisetsu_end'), params);
  },
  updateJohaisetsuBaseArrival(
    params: JohaisetsuReportBaseArrivalUpdateParams): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl('/update_johaisetsu_base_arrival'), params);
  },
  updateJohaisetsuContent(
    params: JohaisetsuReportContentAndRoadParams): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl('/update_johaisetsu_content'), params);
  },
  get(id: number): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse>> {
    return axios.get(utils.getApiUrl(`/johaisetsu_reports/${id}`));
  },
  getCurrent(
    params: JohaisetsuReportGetCurrentParams,
  ): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse>> {
    return axios.get(utils.getApiUrl('/johaisetsu_reports/current'), { params });
  },
  getByCarIds(params: JohaisetsuReportGetByCarIdsParams): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse[]>> {
    return axios.get(utils.getApiUrl('/get_by_car_ids'), { params });
  },
};
