


import {
  defineComponent,
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  PropType,
} from '@vue/composition-api';
import PhotoTaker from '@/components/lib/PhotoTaker.vue';
import johaisetsuSettouPatrolReportApi from '@/apis/johaisetsu_settou_patrol_report';
import {
  RoadNameDirection,
  Location,
} from '@/models/index';
import { SpSettouPatrolReport, SpSettouPatrolReportExt } from '@/models/apis/settou/settouPatrolReportsRequest';
import { setValue } from '@/lib/utils';
import { getImageCandidate } from '@/lib/imageHelper';
import { JohaisetsuCarExt } from '@/models/johaisetsu/spJohaisetsuReport';
import {
  initSpSettouReportState,
  MAX_SAVABLE_PHOTOS_COUNT,
  SETTOU_PATROL_REPORT_FILE_NAME_PREFIX_FOR_DOWNLOAD,
  SETTOU_PATROL_REPORT_WEATHERS,
} from '@/components/Sp/SettouReport/SettouReportCommon/CreateReport/utils/index';
import { SpSettouReportState } from '@/models/spSettouReport';
import CreateReportCommon from '@/components/Sp/SettouReport/SettouReportCommon/CreateReport/index.vue';

export default defineComponent({
  props: {
    johaisetsuCar: {
      type: Object as PropType<JohaisetsuCarExt>,
      required: true,
    },
    currentLocation: {
      type: Object as PropType<Location>,
    },
    selectedJohaisetsuType: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<SpSettouReportState>(initSpSettouReportState());

    const canAddPendingPhoto = computed<boolean>(() => {
      if (!state.settouPatrolReport.imageCandidates) return false;

      return state.settouPatrolReport.imageCandidates.length < MAX_SAVABLE_PHOTOS_COUNT;
    });
    const hasError = computed<boolean>(() => {
      return Object.values(state.errorObj).includes(true);
    });

    const initSettouPatrolReport = (): void => {
      state.settouPatrolReport = {
        lat: null,
        lon: null,
        roadNameDisp: null,
        direction: null,
        kp: null,
        roadCondition: state.roadConditions[0].key,
        snowHeight: null,
        weather: SETTOU_PATROL_REPORT_WEATHERS[0],
        temperature: null,
        roadTemperature: null,
        bikou1: null,
        imageCandidates: [],
      };
    };
    const onRoadNameChange = (): void => {
      const selectedRoadNameDisp = state.settouPatrolReport.roadNameDisp || '';
      const roadNameObj = state.roadNameMap[selectedRoadNameDisp];
      state.directions = roadNameObj ? roadNameObj.directions : [];
      state.settouPatrolReport.direction = null;
    };

    const onFloatParamChange = (evt: Event, inputProp: string, gt: number, lt: number): void => {
      const inputElement = evt.target as HTMLInputElement;
      const val = inputElement.value;
      const isPartial = !val || !!val.match(/^(\+|-)?(\.)?$/);
      const shouldCheckInput = !isPartial;
      const prop = inputProp as keyof SpSettouPatrolReport;
      if (shouldCheckInput) {
        const isNumeric = !isNaN(Number(val));
        const isWithinRange = parseFloat(val) > gt && parseFloat(val) < lt;
        const isDecimalPlaceOk = !val.match(/(\.\d{3,})$/);
        const shouldIgnoreInput = !(isNumeric && isWithinRange && isDecimalPlaceOk);
        inputElement.value = ((shouldIgnoreInput ? state.settouPatrolReport[prop] : val.trim()) || '').toString();
      }
      setValue(state.settouPatrolReport, prop, inputElement.value);
    };
    const onIntParamChange = (evt: Event, prop: keyof SpSettouPatrolReport): void => {
      const inputElement = evt.target as HTMLInputElement;
      const val = inputElement.value;
      const shouldIgnoreInput = val && !val.match(/^\d*$/);
      inputElement.value = ((shouldIgnoreInput ? state.settouPatrolReport[prop] : val.trim()) || '').toString();
      setValue(state.settouPatrolReport, prop, inputElement.value);
    };
    const onImageFileAttach = async(evt: Event) => {
      const fileList = (evt.target as HTMLInputElement).files;
      if (!fileList || fileList.length === 0) return;

      const file = fileList[0];
      attachImageFile(file);
    };
    const attachImageFile = async(file: File): Promise<void> => {
      if (!state.settouPatrolReport.imageCandidates) return;

      state.errorMessage = null;
      if (!canAddPendingPhoto.value) {
        state.errorMessage = '添付画像の最大数は' + MAX_SAVABLE_PHOTOS_COUNT + '枚です。';
        return;
      }

      const imageCandidate = await getImageCandidate(file);
      if (!imageCandidate) return;
      state.settouPatrolReport.imageCandidates.push(imageCandidate);
    };
    const removeImageCandidate = (idx: number): void => {
      if (!state.settouPatrolReport.imageCandidates) return;

      URL.revokeObjectURL(state.settouPatrolReport.imageCandidates[idx].src);
      state.settouPatrolReport.imageCandidates.splice(idx, 1);
    };
    const releaseImageObjectURLs = (): void => {
      if (state.settouPatrolReport.imageCandidates) {
        state.settouPatrolReport.imageCandidates.forEach(e => URL.revokeObjectURL(e.src));
      }
    };
    const getParamsForRequest = (data: SpSettouPatrolReport): SpSettouPatrolReportExt => {
      const { lat, lon } = props.currentLocation || { lat: 0, lon: 0 };
      const kp = data.kp ? parseFloat(data.kp).toFixed(2) : null;
      const temperature = data.temperature ? parseFloat(data.temperature).toFixed(2) : null;
      const roadTemperature = data.roadTemperature ? parseFloat(data.roadTemperature).toFixed(2) : null;
      const reqObj: SpSettouPatrolReportExt = {
        johaisetsu_han_name: props.johaisetsuCar.report.johaisetsuHanName,
        manager_name: props.johaisetsuCar.report.managerName,
        phone_number: props.johaisetsuCar.report.phoneNumber,
        lat,
        lon,
        road_name_disp: data.roadNameDisp,
        direction: data.direction,
        kp,
        road_condition: data.roadCondition,
        snow_height: data.snowHeight,
        weather: data.weather,
        temperature,
        road_temperature: roadTemperature,
        bikou1: data.bikou1,
        images: data.imageCandidates?.map(e => e.file) || [],
      };

      return reqObj;
    };
    const saveSettouPatrolReport = async(): Promise<void> => {
      state.isRequesting = true;
      state.errorMessage = null;
      try {
        const reqObj = getParamsForRequest(state.settouPatrolReport);
        await johaisetsuSettouPatrolReportApi.create(reqObj);
        state.isRequesting = false;
        releaseImageObjectURLs();
        initSettouPatrolReport();
        state.showCompleteCreateModal = true;
      } catch (e) {
        state.isRequesting = false;
        state.errorMessage = '報告書の作成に失敗しました。再度操作を行ってください。';
      }
    };
    const tryRevertCreateReport = (): void => {
      emit('tryRevertCreateReportOnCommon');
    };

    onMounted(async() => {
      state.roadNames = JSON.parse(JSON.stringify(window.master.roadNameDirections.filter(e => !e.isDummy)));
      state.roadNameMap = state.roadNames.reduce<Record<string, RoadNameDirection>>((acc, e) => {
        acc[e.roadNameReal] = e;
        return acc;
      }, {});
      state.roadConditionMap = window.johaisetsuMaster.settouPatrolReportRoadConditionMap;
      state.roadConditions = window.johaisetsuMaster.settouPatrolReportRoadConditions;

      initSettouPatrolReport();
    });
    onBeforeUnmount(() => {
      releaseImageObjectURLs();
    });
    return {
      SETTOU_PATROL_REPORT_FILE_NAME_PREFIX_FOR_DOWNLOAD,
      SETTOU_PATROL_REPORT_WEATHERS,
      state,
      // computed
      canAddPendingPhoto,
      hasError,
      onRoadNameChange,
      onFloatParamChange,
      onIntParamChange,
      onImageFileAttach,
      attachImageFile,
      removeImageCandidate,
      saveSettouPatrolReport,
      tryRevertCreateReport,
    };
  },
  components: {
    PhotoTaker,
    CreateReportCommon,
  },
});
