


import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
  reactive,
  onMounted,
} from '@vue/composition-api';

import {
  RoadNameDirectionShortcut,
  RoadNameDirectionShortcutName,
} from '@/models';
import dataLayerLegends from '@/components/lib/dataLayerLegend';
import { dtFormat } from '@/lib/dateHelper';
import {
  PANE_SIDE_HARD_MAX_WIDTH,
  LIST_HARD_MAX_HEIGHT,
} from '@/components/Johaisetsu/JohaisetsuMap/consts/johaisetsu_map';
import useJohaisetsuCars from '@/components/Johaisetsu/JohaisetsuMap/composables/useJohaisetsuCars';
import { useStore } from '@/hooks/useStore';
import { Settings as UserSettings } from '@/models/apis/user/userResponse';
import { getInitTimeChoices } from '@/lib/utils';
import {
  PaneStyle,
  GeoItemLayerOrder,
  GeoPaneState,
  MiscInfoForCar,
} from '@/models/johaisetsu/johaisetsuMap';
import { State } from '@/models/useComment';
import geoItemSearchComponents from '@/components/lib/geoItemSearchComponents';
import GeoItemSearchComment from '@/components/lib/geoItemSearchComponents/GeoItemSearchComment.vue';
import { initGeoPaneState } from '@/components/Johaisetsu/JohaisetsuMap/utils/index';
import { typeRemarks } from '@/lib/johaisetsu/johaisetsuCommonUtil';
import { UseWeatherObservatoryState } from '@/models/useWeatherObservatory';
import { JohaisetsuCarExt } from '@/models/johaisetsu/johaisetsuCar';

export default defineComponent({
  name: 'realtime-mode-pane',
  props: {
    showCarIcons: {
      type: Boolean,
      default: true,
    },
    paneStyle: {
      type: Object as PropType<PaneStyle>,
      default: () => ({
        paneSideMinWidth: PANE_SIDE_HARD_MAX_WIDTH,
        paneSideMaxWidth: PANE_SIDE_HARD_MAX_WIDTH,
        listMinHeight: LIST_HARD_MAX_HEIGHT,
        listMaxHeight: LIST_HARD_MAX_HEIGHT,
      }),
    },
    commentState: {
      type: Object as PropType<State>,
      required: true,
    },
    weatherObservatoryState: {
      type: Object as PropType<UseWeatherObservatoryState>,
      required: true,
    },
    canUseWeatherObservatory: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const userState = store.state.user;
    const userSettings = computed<UserSettings>(() => {
      return userState.settings;
    });
    const state = reactive<GeoPaneState>(initGeoPaneState());
    const { carState } = useJohaisetsuCars();
    const johaisetsuRole = computed<string>(() => {
      return userState.johaisetsu_role;
    });
    const carKindChoices = computed(() => {
      const uniqueCarKinds: Record<string, { val: string; selected: boolean }> = {};
      const savedCarKinds: Record<string, boolean> = JSON.parse(localStorage.getItem('selectedCarKinds') || '{}');
      carState.cars.forEach(car => {
        const carKind = car.car_kind;
        const carKindEntry = state.carKinds.find(entry => entry.key === carKind);
        if (!carKindEntry) return;
        uniqueCarKinds[carKindEntry.key] = {
          val: carKindEntry.val,
          selected: savedCarKinds[carKindEntry.key] ?? true,
        };
      });
      return Object.entries(uniqueCarKinds).map(([key, { val, selected }]) => ({key, val, selected}));
    });
    const shouldShowStallRiskLayerUI = computed<boolean>(() => {
      if (!userSettings.value.g1name) { return false; }
      return userSettings.value.g1name.indexOf('首都高') !== -1;
    });
    const numVisibleGeoItemLayers = computed<number>(() => {
      return Object.entries(state.itemShow).filter(ent => !!ent[1]).length;
    });
    const isMaxVisibleGeoItemLayersReached = computed<boolean>(() => {
      return numVisibleGeoItemLayers.value >= 1;
    });
    const shouldShowJohaisetsuLayerUI = computed<boolean>(() => {
      return johaisetsuRole.value !== null;
    });
    const isCarShouldShow = (car: JohaisetsuCarExt): boolean => {
      return car.shouldShow && (carKindChoices.value.find(x => x.key === car.car_kind)?.selected ?? false);
    };
    const isSettouSagyou = (layerType: string): boolean => {
      return layerType !== '報告書';
    };
    const getCarType = (carKind: string): MiscInfoForCar | null => {
      return typeRemarks[carKind] || null;
    };
    const refGISearchComment = ref<InstanceType<typeof GeoItemSearchComment>[]>();

    // モード変更をして表示のチェックボックスが初期化された際に
    // propsの値を正しく反映させるために、watchで監視する
    watch(() => props.showCarIcons, () => {
      state.checkedShowCarIcons = props.showCarIcons;
    });

    watch(() => state.carKinds,
      (newVal) => {
        if (newVal) {
          watch(
            () => carState.cars.map(car => `${car.id}-${car.shouldShow}`),
            (newValues, oldValues) => {
              newValues.forEach((newVal, index) => {
                if (newVal && newVal !== oldValues[index]) {
                  emit('filter-car-on-change');
                }
              });
            },
            { deep: true },
          );
        }
      },
      { immediate: true },
    );

    type DateType = 'temperature' | 'salinity' | 'snowfall' | 'frozen' | 'snow_mountain' | 'jh_work_status'
    | 'tairyu' | 'ensui_sanpu' | 'shitsuen_sanpu' | 'kokeizai_sanpu' | 'kyokusho_sanpu' | 'other_sanpu'
    | 'sweeper_soukou' | 'other_soukou' | 'kisei' | 'josetsu' | 'haisetsu' | 'settouPatrolReports';
    const changeGeoItemsLayer = async(dataType: DateType) => {
      const orderDataTypeKey = dataType;
      if (state.itemShow[dataType]) {
        state.itemOrder[orderDataTypeKey] = numVisibleGeoItemLayers.value;
      } else {
        const deletedOrder = state.itemOrder[orderDataTypeKey];
        delete state.itemOrder[orderDataTypeKey];
        // 順番の付け替え
        for (const [k, v] of Object.entries(state.itemOrder)) {
          if (deletedOrder && v > deletedOrder) {
            const orderDataTypeKey = k as keyof GeoItemLayerOrder;
            state.itemOrder[orderDataTypeKey] = (state.itemOrder[orderDataTypeKey] || 0) - 1;
          }
        }
      }
      // 再取得、再表示
      await refreshGeoItemsLayer();
    };
    const filterCars = () => {
      if (carKindChoices.value.length === 0) return;
      const selectedCarKinds = carKindChoices.value.filter(e => e.selected).map(e => e.key);
      carState.cars.forEach(car => {
        car.shouldShow = selectedCarKinds.includes(car?.car_kind || '');
      });
      saveCarKindSelections();
      emit('filter-car-on-change');
    };
    const johaisetsuCarKindDisp = (car: JohaisetsuCarExt): string => {
      return car?.device?.car_kind_disp ?? '';
    };
    const saveCarKindSelections = () => {
      const selections: Record<string, boolean> = {};
      carKindChoices.value.forEach(carKind => {
        selections[carKind.key] = carKind.selected;
      });
      localStorage.setItem('selectedCarKinds', JSON.stringify(selections));
    };
    const roadNameShortcutListChanged = (obj1: RoadNameDirectionShortcut, obj2: RoadNameDirectionShortcutName) => {
      let areaChanged = false;
      const areaObj: Record<string, number> = {};
      const directionObj: Record<string, number> = {};
      let selected = false;
      if (obj1.key === 'area') {
        areaChanged = true;
        areaObj[obj2.key] = 1;
        state.roadNameDirectionShortcutMap.direction.arr.forEach(e => {
          if (e.selected) {
            directionObj[e.key] = 1;
          }
        });
        selected = obj2.selected || false;
      } else {
        state.roadNameDirectionShortcutMap.area.arr.forEach(e => {
          if (e.selected) {
            areaObj[e.key] = 1;
          }
        });
        directionObj[obj2.key] = 1;
        selected = obj2.selected || false;
      }
      state.roadNameDirections.forEach(e1 => {
        if (e1.isDummy) return;
        if (!areaObj[e1.area]) return;
        e1.directions.forEach(e2 => {
          if (areaChanged && !selected) {
            // エリアが非選択にされたら、方向の選択肢にかかわらず
            // 当該エリアは全落とし
            e2.selected = false;
          } else {
            if (!directionObj[e2.direction]) return;
            e2.selected = selected;
          }
        });
      });
      roadNameDirectionListChanged();
    };
    const onClickCarOnCarList = (car: JohaisetsuCarExt) => {
      emit('click-car-on-car-list', car);
    };
    const onChangeShowCarIcons = () => {
      emit('change-show-car-icons');
    };
    const refreshGeoItemsLayer = () => {
      emit(
        'refresh-geo-items-layer',
        state.itemOrder,
        state.geoItemSearchConds,
        state.itemShow,
        state.roadNameDirections,
      );
    };
    const roadNameDirectionListChanged = () => {
      emit(
        'road-name-direction-list-changed',
        state.itemOrder,
        state.roadNameDirections,
      );
    };
    const toggleSettouReportsLayer = () => {
      emit('toggle-settou-reports-layer', state.itemShow.settouPatrolReports);
    };
    const refreshLayers = () => {
      emit(
        'refresh-layers',
        state.itemOrder,
        state.geoItemSearchConds,
        state.itemShow,
        state.itemShow.settouPatrolReports,
        state.show.ensuiPlants,
        state.roadNameDirections,
      );
    };
    const toggleStallRiskPointsLayer = () => {
      if (state.show.stallRiskPoints) {
        emit('change-show-stall-risk-point');
      } else {
        emit('change-remove-stall-risk-point');
      }
    };
    const toggleEnsuiPlantsLayer = () => {
      emit('change-show-ensui-plants', state.show.ensuiPlants);
    };
    const changeGeoItemLayers = (itemName: string) => {
      emit('change-geo-items-layers', itemName, refGISearchComment);
    };
    const refreshGeoItemLayers = () => {
      emit('refresh-geo-items-layers');
    };
    const toggleWeatherObservatoryLayer = () => {
      emit('toggle-weather-observatory-layer');
    };

    onMounted(async() => {
      state.geoItemTimeChoices = getInitTimeChoices();
      const master = await window.master.$promise;
      state.carKinds = master.lovs.car_kind.vals;
      state.roadNameDirections = JSON.parse(JSON.stringify(master.roadNameDirections));
      state.roadNameDirectionShortcuts =
          JSON.parse(JSON.stringify(master.roadNameDirectionShortcuts));
      state.roadNameDirectionShortcutMap = Object.fromEntries(
        state.roadNameDirectionShortcuts.map(e => [e.key, e]),
      );

      state.roadNameDirections.forEach(e1 => {
        if (e1.isDummy) return;
        e1.directions.forEach(e2 => {
          e2.selected = true;
        });
      });
      state.roadNameDirectionShortcuts.forEach(e1 => {
        e1.arr.forEach(e2 => {
          e2.selected = true;
        });
      });
    });

    return {
      // refs
      state,
      carState,
      carKindChoices,
      dtFormat,
      isMaxVisibleGeoItemLayersReached,
      shouldShowStallRiskLayerUI,
      shouldShowJohaisetsuLayerUI,
      refGISearchComment,
      // methods
      getCarType,
      changeGeoItemsLayer,
      changeGeoItemLayers,
      filterCars,
      isCarShouldShow,
      isSettouSagyou,
      onClickCarOnCarList,
      onChangeShowCarIcons,
      refreshLayers,
      refreshGeoItemLayers,
      roadNameShortcutListChanged,
      roadNameDirectionListChanged,
      toggleStallRiskPointsLayer,
      toggleEnsuiPlantsLayer,
      toggleSettouReportsLayer,
      toggleWeatherObservatoryLayer,
      johaisetsuCarKindDisp,
    };
  },
  components: {
    ...dataLayerLegends,
    ...geoItemSearchComponents,
  },
});
