import { reactive, nextTick, ref, Ref } from '@vue/composition-api';

import johaisetsuCarApi from '@/apis/johaisetsu_car';
import johaisetsuReportApi from '@/apis/johaisetsu_report';

import {
  CarState,
  SearchState,
} from '@/models/johaisetsu/johaisetsuMap';
import { parseDateTimeString } from '@/lib/dateTimeUtil';
import {
  createInitialCarsData,
  convData,
  shouldShowCar,
  deselectReportItems,
  loadJohaisetsuMtxs,
} from '@/components/Johaisetsu/JohaisetsuMap/utils';
import { createInjection } from '@/lib/createInjection';
import { IntervalID } from '@/lib/requestAnimationFrame';
import { JohaisetsuCarExt } from '@/models/johaisetsu/johaisetsuCar';
import { JOHAISETSU_CAR_MAX_MTXS } from '@/components/Johaisetsu/JohaisetsuMap/consts/johaisetsu_map';
import { STATUS_STOPPED } from '@/lib/johaisetsu/johaisetsuReportUtil';

const RECENT_DAYS = 60;

interface InjectedState {
  carState: CarState;
  searchState: SearchState;
}
export interface UseJohaisetsuCarsResult {
  carState: CarState;
  startCarUpdateInterval: () => Promise<void>;
  stopCarUpdateInterval: () => void;
  filterCars: () => void;
  getSelectedCar: () => JohaisetsuCarExt | null;
  selectCar: (carId: number) => Promise<void>;
  deselectCar: (carId: number) => boolean;
  deselectAllCars: () => void;
  clearCars: () => void;
  redrawCarLayerWatchValue: Ref<number>;
  notifyRedrawCarLayerNeeded: () => void;
}

const { provide, inject } = createInjection<InjectedState>('johaisetsuMap/useJohaisetsuCars');

export function provideJohaisetsuCars(searchState: SearchState): InjectedState {
  const carState = reactive<CarState>({
    cars: [],
    carsUpdatedAt: new Date(),
  });
  const injectedState = { carState, searchState };
  provide(injectedState);
  return injectedState;
}

export default function useJohaisetsuCars(defaultInjectedState?: InjectedState): UseJohaisetsuCarsResult {
  const injectedState = defaultInjectedState ?? inject();
  const carState = injectedState.carState;
  const searchState = injectedState.searchState;
  let carUpdateTimer: IntervalID | null = null;
  const redrawCarLayerWatchValue = ref<number>(0);

  const startCarUpdateInterval = async() => {
    window.clearRequestInterval(carUpdateTimer);
    await refreshCars();
    // 作業車がときによって表示に時間かかるため
    setTimeout(() => {
      refreshCars();
    }, 1000);
    carUpdateTimer = window.requestInterval(async() => {
      await refreshCars();
    }, 10000);
  };
  const stopCarUpdateInterval = () => {
    window.clearRequestInterval(carUpdateTimer);
  };

  const notifyRedrawCarLayerNeeded = () => {
    redrawCarLayerWatchValue.value += 1;
  };

  const filterCars = () => {
    // 検索ボタンが押された時に、検索条件を確定する
    searchState.decidedSearchConds = { ...searchState.searchConds };
    setCarShouldShow();
  };

  const setCarShouldShow = () => {
    carState.cars.forEach(car => {
      car.shouldShow = shouldShowCar(
        car,
        searchState.decidedSearchConds,
        searchState.johaisetsuCompanyAreaMap,
        searchState.johaisetsuCompanies,
      );
    });
  };

  const refreshCars = async() => {
    const { data: johaisetsuCars } = await johaisetsuCarApi.getJohaisetsuCars();
    const recentTs = new Date().setDate(new Date().getDate() - RECENT_DAYS);
    const recentJohaisetsuCars = johaisetsuCars.filter(johaisetsuCar => {
      try {
        const tsDate = parseDateTimeString(johaisetsuCar.ts);
        return tsDate.getTime() > recentTs;
      } catch (e) {
        return false;
      }
    });
    // https://land-japan.slack.com/archives/C0684TML3GE/p1709905753710319?thread_ts=1709905319.428209&cid=C0684TML3GE
    // stoppedになってたら直ちに無視するのは少し判定が雑な気もしつつ(少し電波が途切れただけなどもありうるから)、
    // 一覧検索の結果であれば別に多少雑でも問題ないかという判断.
    const movingCars = recentJohaisetsuCars.filter(car => car.status !== STATUS_STOPPED);
    if (movingCars.length > 0) {
      const { data: reports } = await johaisetsuReportApi.getByCarIds({
        johaisetsu_car_ids: movingCars.map(car => car.id),
      });
      movingCars.forEach(car => {
        car.report = reports.find(e => e.device_id === car.device_id) ?? null;
      });
    }

    // carState.carsが空の場合は固定値、2回目以降は自分で持ってるやつ
    const isInitial = carState.cars.length === 0;
    if (isInitial) {
      carState.cars = createInitialCarsData(recentJohaisetsuCars);
    }
    const currentCarInfoMap = Object.fromEntries(
      carState.cars.map(e => [
        e.id,
        {
          isSelected: e.isSelected,
          selectedMtxTs: e.reportExt?.mtxsExt.find(mtx => mtx.isSelected)?.ts ?? '',
          loadedMtxs: e.reportExt?.mtxsExt ?? [],
        },
      ]),
    );
    const master = await window.master.$promise;
    carState.cars = convData({
      cars: recentJohaisetsuCars,
      currentCarInfoMap,
      carKindMap: master.lovs.car_kind.map,
      johaisetsuCompanies: master.johaisetsuRoles.vals,
    });
    setCarShouldShow();
    carState.carsUpdatedAt = new Date();
    nextTick(() => {
      notifyRedrawCarLayerNeeded();
    });
  };

  const getSelectedCar = (): JohaisetsuCarExt | null => {
    return carState.cars.find(e => e.isSelected) ?? null;
  };

  const selectCar = async(carId: number): Promise<void> => {
    for (const car of carState.cars) {
      car.isSelected = car.id === carId;
      const report = car.reportExt;
      if (!report) continue;

      if (!report.isMtxsLoaded) {
        await loadJohaisetsuMtxs([report], JOHAISETSU_CAR_MAX_MTXS);
      }
    }
  };

  const deselectAllCars = () => {
    carState.cars.forEach(car => {
      car.isSelected = false;
    });
  };

  const deselectCar = (carId: number): boolean => {
    const car = getSelectedCar();
    if (!car || car.id !== carId) return false;

    car.isSelected = false;
    if (car.reportExt) {
      deselectReportItems(car.reportExt);
    }
    return true;
  };

  const clearCars = (): void => {
    carState.cars = [];
  };

  return {
    carState,
    startCarUpdateInterval,
    stopCarUpdateInterval,
    filterCars,
    getSelectedCar,
    selectCar,
    deselectCar,
    deselectAllCars,
    clearCars,
    redrawCarLayerWatchValue,
    notifyRedrawCarLayerNeeded,
  };
}
