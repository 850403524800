


import {
  ref,
  defineComponent,
  computed,
  onMounted,
  reactive,
  onUnmounted,
  watch,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import PrintPagination from '@/components/lib/PrintPagination/index.vue';
import {
  convReport,
  convSearchParamTsTo,
  loadJohaisetsuMtxs,
} from '@/components/Johaisetsu/JohaisetsuMap/utils/index';
import { Settings as UserSettings } from '@/models/apis/user/userResponse';
import { JohaisetsuReport,
  JohaisetsuReportExt,
} from '@/models/apis/johaisetsu/johaisetsuReportResponse';

import { ExtremeMapEssentials } from '@/models/johaisetsu/johaisetsuMap';
import johaisetsuReportApi from '@/apis/johaisetsu_report';
import {
  waitForJohaisetsuMasters,
  filterReportsByJohaisetsuType,
  getReportsWithBaseArrivalTimestamp,
} from '@/lib/johaisetsuHelper';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { dtFormat, dateStrToDate } from '@/lib/dateHelper';
import { setPrintPageSize } from '@/lib/printPageHelper';
import { JOHAISETSU_REPORT_MAX_MTXS } from '@/components/Johaisetsu/JohaisetsuMap/consts/johaisetsu_map';
import {
  CURRENT_PAGE_DEFAULT,
  ITEMS_PER_PAGE_DEFAULT,
  ITEMS_PER_PAGE_LIMIT,
} from '@/consts/report_print';

interface JohaisetsuPrintReport extends JohaisetsuReportExt {
  isMapAvailable: boolean;
}
interface PrintJohaisetsuReportState {
  isReady: boolean;
  reports: JohaisetsuReportExt[];
  paginatedReports: JohaisetsuPrintReport[];
  extremeMapEssentials: ExtremeMapEssentials | null;
  inputItemsPerPage: string;
}
export default defineComponent({
  name: 'PrintJohaisetsuReport',
  setup() {
    const state = reactive<PrintJohaisetsuReportState>({
      isReady: false,
      reports: [],
      paginatedReports: [],
      extremeMapEssentials: null,
      inputItemsPerPage: ITEMS_PER_PAGE_DEFAULT.toString(),
    });
    const shouldShowPagination = computed<boolean>(() => {
      return state.reports.length > ITEMS_PER_PAGE_DEFAULT;
    });
    const store = useStore();
    const userState = store.state.user;
    const userSettings = computed<UserSettings>(() => {
      return userState.settings;
    });
    const { route, router } = useRoute();
    const johaisetsuReportId = computed<string>(() => route.value.params.johaisetsuReportId);
    const currentPage = computed<number>(() => {
      const page = Number(route.value.params.currentPage);
      return isNaN(page) ? CURRENT_PAGE_DEFAULT : page;
    });
    const itemsPerPage = computed<number>(() => {
      const items = Number(route.value.params.itemsPerPage);
      if (isNaN(items) || items < CURRENT_PAGE_DEFAULT) return ITEMS_PER_PAGE_DEFAULT;
      // 1ページあたりの表示件数が上限を超えている場合は上限値を設定
      return Math.min(items, ITEMS_PER_PAGE_LIMIT);
    });
    const reportDtFormat = (dt: Date) => dtFormat(dt, 'yyyy年m月d日 HH時MM分');
    const extremeMap = ref<InstanceType<typeof ExtremeMap>[]>([]);
    const convPrintReport = async(reports: JohaisetsuReportExt[]): Promise<JohaisetsuPrintReport[]> => {
      await loadJohaisetsuMtxs(reports, JOHAISETSU_REPORT_MAX_MTXS);
      return reports.map(report => ({
        ...report,
        isMapAvailable: false,
        contentsDisp: report.johaisetsu_type ? `${report.johaisetsu_type}(${report.work_type})` : '',
      }));
    };

    const searchReport = async(): Promise<JohaisetsuReport[]> => {
      if (johaisetsuReportId.value) {
        const { data } = await johaisetsuReportApi.get(parseInt(johaisetsuReportId.value));
        return [ data ];
      }
      const {
        johaisetsuCompanyIds,
        johaisetsuHanName,
        johaisetsuType,
        workType,
        dateFrom,
        dateTo,
      } = route.value.params;
      const searchParam = {
        ts_from: dateStrToDate(dateFrom) || new Date(),
        ts_to: convSearchParamTsTo(dateStrToDate(dateTo) || new Date()),
        johaisetsu_company_ids: johaisetsuCompanyIds === 'all'
          ? []
          : johaisetsuCompanyIds.split(',').map(e => parseInt(e)),
        johaisetsu_han_name: johaisetsuHanName === 'all' ? '' : johaisetsuHanName,
        johaisetsu_type: johaisetsuType === 'all' ? '' : johaisetsuType,
        work_type: workType === 'all' ? '' : workType,
      };
      const { data } = await johaisetsuReportApi.index(searchParam);
      const reportsWithArrivalTimestamp = getReportsWithBaseArrivalTimestamp(data);
      return filterReportsByJohaisetsuType(reportsWithArrivalTimestamp);
    };
    watch(() => extremeMap.value, () => {
      if (!extremeMap.value) return;
      showJohaisetsuReport();
    });
    const showJohaisetsuReport = () => {
      const h = window.innerHeight - 300;
      for (let i = 0; i < state.paginatedReports.length; i++) {
        // extremeMap.valueでwatchすると、地図を表示し終わったものも含まれるため、
        // 地図を表示し終わったものは処理せず再描画されるのを防ぐ
        if (!extremeMap.value[i] || state.paginatedReports[i].isMapAvailable) {
          continue;
        }
        extremeMap.value[i].setMapHeight(h);
        extremeMap.value[i].triggerResize();
        extremeMap.value[i].removeJohaisetsuReportLayer();
        extremeMap.value[i].showJohaisetsuReportLayer(state.paginatedReports[i], true);
        state.paginatedReports[i].isMapAvailable = true;
      }
    };
    const paginatePrintReport = async() => {
      const nextPageIdx = currentPage.value * itemsPerPage.value;
      const minIdx = nextPageIdx - itemsPerPage.value;
      const reports = state.reports.slice(minIdx, nextPageIdx);
      state.paginatedReports = await convPrintReport(reports);
    };
    const moveToSelectedPage = (page: number, items: number) => {
      const {
        dateFrom,
        dateTo,
        johaisetsuCompanyIds,
        johaisetsuHanName,
        johaisetsuType,
        workType,
      } = route.value.params;
      const routeObj = {
        name: 'PrintJohaisetsuReportsAll',
        params: {
          dateFrom,
          dateTo,
          johaisetsuCompanyIds,
          johaisetsuHanName,
          johaisetsuType,
          workType,
          currentPage: page.toString(),
          itemsPerPage: items.toString(),
        },
      };
      const obj = router.resolve(routeObj);
      window.location.href = obj.href;
    };
    const onClickPageChange = (pageNum: number) => {
      moveToSelectedPage(pageNum, itemsPerPage.value);
    };
    const onClickItemsPerPageChange = (itemsPerPage: number) => {
      moveToSelectedPage(CURRENT_PAGE_DEFAULT, itemsPerPage);
    };
    onMounted(async() => {
      document.body.classList.add('A4');
      setPrintPageSize('A4 portrait');

      await Promise.all([waitForUserAndMasters(), waitForJohaisetsuMasters()]);

      state.extremeMapEssentials = {
        userSettings: userSettings.value,
        kpMap: window.master.kpMap,
      };
      state.inputItemsPerPage = itemsPerPage.value.toString();

      const reports = await searchReport();
      state.reports = reports.map(e => convReport(e, window.master.johaisetsuRoles.vals));
      await paginatePrintReport();

      state.isReady = true;
    });
    onUnmounted(() => {
      document.body.classList.remove('A4');
    });

    return {
      state,
      // ref
      extremeMap,
      // computed
      shouldShowPagination,
      currentPage,
      itemsPerPage,
      reportDtFormat,
      dtFormat,
      onClickPageChange,
      onClickItemsPerPageChange,
    };
  },
  components: {
    PrintPagination,
  },
});
