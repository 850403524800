













































































































































































































import {
  defineComponent,
  computed,
  ref,
} from '@vue/composition-api';
// TODO: Vue3＋Vuex4/VueRouter4にアップデート後に、useStoreに置き換える
import { useRoute } from '@/hooks/useRoute';
import { useStore } from '@/hooks/useStore';
import { Ability } from '@/models/apis/user/userResponse';
import { UserActionTypes, UserGetterTypes } from '@/store/modules/user';
import * as ABILITY from '@/consts/ability';
import { isQualifiedForGeneralPage } from '@/lib/abilityHelper';
import { isJohaisetsuCompanyDefaultUser } from '@/lib/johaisetsu/johaisetsuCommonUtil';

export default defineComponent({
  name: 'global-header',
  setup() {
    const store = useStore();
    const { route } = useRoute();
    const userState = store.state.user;
    const navbarCollapse = ref<HTMLElement>();
    const navbarDropdownMenu1 = ref<HTMLElement>();
    const navbarDropdownMenu1Sub = ref<HTMLElement>();

    const username = computed<string>(() => {
      return userState.username;
    });
    const displayName = computed<string>(() => {
      return userState.display_name;
    });
    const isAdmin = computed<boolean>(() => {
      return userState.has_role_admin;
    });
    const isSuperAdmin = computed<boolean>(() => {
      return userState.has_role_super_admin;
    });
    const johaisetsuRole = computed<string>(() => {
      return userState.johaisetsu_role;
    });
    const abilityMap = computed<Record<number, Ability>>(() => {
      return userState.abilityMap;
    });
    const isLoggedIn = computed<boolean>(() => {
      return store.getters[UserGetterTypes.IS_LOGGED_IN];
    });
    const routeName = computed<string>(() => {
      return route.value.name || '';
    });
    const logoRouteName = computed<string>(() => {
      return isQualifiedForGeneralPage(userState) ? 'Top' : '';
    });
    const isAdminPageGroup = computed<boolean>(() => {
      return (route.value?.name || '').indexOf('Admin') === 0;
    });
    const isDevDebugPageGroup = computed<boolean>(() => {
      return (route.value?.name || '').indexOf('DevDebug') === 0;
    });
    const canUseJohaisetsuKanri = computed<boolean>(() => {
      const hasAbilityOtherThanJohaisetsu = Object.keys(abilityMap.value).some(key => {
        return parseInt(key) !== ABILITY.ABILITY_MESSAGE_BOARD;
      });
      return johaisetsuRole.value !== null && hasAbilityOtherThanJohaisetsu;
    });
    const isJohaisetsuKanriRoleHonsha = computed<boolean>(() => {
      return johaisetsuRole.value === 'honsha';
    });
    const isJohaisetsuKanriRoleKyoku = computed<boolean>(() => {
      return ['t-east', 't-west', 'kanagawa'].includes(johaisetsuRole.value);
    });
    const canUseJohaisetsuEnsuiJutenKaradanpuInput = computed<boolean>(() => {
      return !!abilityMap.value[ABILITY.ABILITY_ENSUI_JUTEN_KARADANPU_INPUT];
    });
    const canUseJohaisetsuEnsuiJutenKaradanpuMap = computed<boolean>(() => {
      return !!abilityMap.value[ABILITY.ABILITY_ENSUI_JUTEN_KARADANPU_MAP];
    });
    const canUseJohaisetsuSekisetsuInput = computed<boolean>(() => {
      return !!abilityMap.value[ABILITY.ABILITY_SEKISETSU_INPUT];
    });
    const canUseJohaisetsuHaisetsuKeisanInput = computed<boolean>(() => {
      return !!abilityMap.value[ABILITY.ABILITY_HAISETSU_KEISAN_INPUT];
    });
    const canUseJohaisetsuProgressTable = computed<boolean>(() => {
      return !!abilityMap.value[ABILITY.ABILITY_PROGRESS_TABLE];
    });
    const canUseJohaisetsuSagyouJoukyouInput = computed<boolean>(() => {
      return !!abilityMap.value[ABILITY.ABILITY_SAGYOU_JOUKYOU_INPUT];
    });
    const canUseJohaisetsuSagyouJoukyouMap = computed<boolean>(() => {
      return !!abilityMap.value[ABILITY.ABILITY_SAGYOU_JOUKYOU_MAP];
    });
    const canUseJohaisetsuSettouPatrolReportList = computed<boolean>(() => {
      return !!abilityMap.value[ABILITY.ABILITY_SETTOU_PATROL_REPORT_LIST];
    });
    const canUseJohaisetsuEnsuiPlantStockTable = computed<boolean>(() => {
      return !!abilityMap.value[ABILITY.ABILITY_ENSUI_PLANT_STOCK_TABLE];
    });
    const canJohaisetsuMessageBoard = computed<boolean>(() => {
      return !!abilityMap.value[ABILITY.ABILITY_MESSAGE_BOARD];
    });
    const canUseJohaisetsuMap = computed<boolean>(() => {
      return isJohaisetsuCompanyDefaultUser(userState.johaisetsu_role);
    });
    const canUseJohaisetsuWork = computed<boolean>(() => {
      return canUseJohaisetsuEnsuiJutenKaradanpuInput.value ||
        canUseJohaisetsuEnsuiJutenKaradanpuMap.value;
    });
    const isJohaisetsuOnly = computed<boolean>(() => {
      return userState.is_johaisetsu_only;
    });
    const canUseCleaningMap = computed<boolean>(() => {
      return !!abilityMap.value[ABILITY.ABILITY_CLEANING_MAP];
    });
    const isCleaningOnly = computed<boolean>(() => {
      return userState.is_cleaning_only;
    });
    const canUseWeatherObservatoryDataPointsTable = computed<boolean>(() => {
      return userState.settings.can_use_weather_observatory;
    });
    const isActiveJohaisetsuKanri = computed<boolean>(() => {
      if (routeName.value === 'JohaisetsuMap') return false;
      return routeName.value.startsWith('Johaisetsu') ||
        routeName.value.startsWith('SettouPatrolReport') ||
        routeName.value === 'EnsuiPlantStockTable' ||
        routeName.value === 'WeatherObservatoryDataPointsTable';
    });

    const onNavVarClick = (evt: Event) => {
      // 押されたら閉じる
      const target = evt.target as HTMLElement;
      const classList = target.classList;
      if (
        (classList.contains('nav-link') || classList.contains('dropdown-item')) &&
        !classList.contains('dropdown-toggle')
      ) {
        if (navbarCollapse.value) {
          $(navbarCollapse.value).collapse('hide');
        }
        if (navbarDropdownMenu1.value) {
          $(navbarDropdownMenu1.value).removeClass('show');
        }
        if (navbarDropdownMenu1Sub.value) {
          navbarDropdownMenu1Sub.value.style.display = 'none';
        }
      }
    };
    const logout = () => {
      store.dispatch(UserActionTypes.LOGOUT)
        .then(res => {
          const redirectUrl = res['redirect_url'];
          // want to explicitly reload
          location.href = redirectUrl || '/login';
        });
    };
    const subMenuOnclick = (e: Event) => {
      const element = e.target as HTMLElement;
      if (element) {
        $(element).next('div').toggle();
      }
      e.stopPropagation();
      e.preventDefault();
    };
    return {
      navbarCollapse,
      navbarDropdownMenu1,
      navbarDropdownMenu1Sub,
      // computed
      username,
      displayName,
      isAdmin,
      isSuperAdmin,
      johaisetsuRole,
      abilityMap,
      isLoggedIn,
      routeName,
      logoRouteName,
      isAdminPageGroup,
      isDevDebugPageGroup,
      canUseJohaisetsuKanri,
      isJohaisetsuKanriRoleHonsha,
      isJohaisetsuKanriRoleKyoku,
      canUseJohaisetsuEnsuiJutenKaradanpuInput,
      canUseJohaisetsuEnsuiJutenKaradanpuMap,
      canUseJohaisetsuSekisetsuInput,
      canUseJohaisetsuHaisetsuKeisanInput,
      canUseJohaisetsuProgressTable,
      canUseJohaisetsuSagyouJoukyouInput,
      canUseJohaisetsuSagyouJoukyouMap,
      canUseJohaisetsuSettouPatrolReportList,
      canUseJohaisetsuEnsuiPlantStockTable,
      canUseJohaisetsuMap,
      canUseJohaisetsuWork,
      canUseWeatherObservatoryDataPointsTable,
      canJohaisetsuMessageBoard,
      isJohaisetsuOnly,
      canUseCleaningMap,
      isCleaningOnly,
      isActiveJohaisetsuKanri,
      // mothods
      onNavVarClick,
      subMenuOnclick,
      logout,
    };
  },
});
