


import {
  defineComponent,
  reactive,
  PropType,
} from '@vue/composition-api';
import { useRoute } from '@/hooks/useRoute';
import useSettouReport from '@/components/Sp/SettouReport/composables/useSettouReport';
import StartWork from '@/components/Sp/SettouReport/SettouReportCommon/StartWork/index.vue';
import { Location } from '@/models/index';
import {
  JohaisetsuSettouPatrolCreateParamsCommon,
} from '@/models/apis/johaisetsu/settouPatrol/johaisetsuSettouPatrolRequestCommon';
import {
  JohaisetsuSettouOnsiteManagementCreateParams,
} from '@/models/apis/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsuSettouOnsiteManagementRequest';

interface State {
  workType: string;
  roadNameDisp: string;
  workArea: string;
  isRequesting: boolean;
}

export default defineComponent({
  props: {
    displayJohaisetsuHan: {
      type: String,
      required: true,
    },
    selectedJohaisetsuType: {
      type: String,
      required: true,
    },
    currentLocation: {
      type: Object as PropType<Location>,
    },
    isRequesting: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      workType: '',
      roadNameDisp: '',
      workArea: '',
      isRequesting: false,
    });
    const {
      state: useSettouReportState,
      roadNames,
      johaisetsuTypes,
      johaisetsuTypeMap,
      isShowJohaisetsuTypeSelect,
    } = useSettouReport({ selectedJohaisetsuType: props.selectedJohaisetsuType });

    const startMoving = async(createReportParameters: JohaisetsuSettouPatrolCreateParamsCommon) => {
      if (!state.workType) {
        emit('showErrorOnCommon', '作業種別が必須です。');
        return;
      }

      const createSettouOnsiteManagementDetailParameters: JohaisetsuSettouOnsiteManagementCreateParams = {
        ...createReportParameters,
        road_name_disp: state.roadNameDisp,
        work_area: state.workArea,
        work_type: johaisetsuTypeMap[state.workType].work_type,
      };

      emit('startMovingOnCommon', createSettouOnsiteManagementDetailParameters, state.workType);
    };
    const showErrorMsg = (msg: string) => {
      emit('showErrorOnCommon', msg);
    };

    const { route } = useRoute();
    const saveLocalStorage = () => {
      const obj = { selectedJohaisetsuType: useSettouReportState.selectedJohaisetsuType };
      const localStorageKey = route.value.name;
      if (!localStorageKey) return;
      localStorage.setItem(localStorageKey, JSON.stringify(obj));
    };

    return {
      state,
      roadNames,
      johaisetsuTypes,
      isShowJohaisetsuTypeSelect,
      startMoving,
      showErrorMsg,
      saveLocalStorage,
    };
  },
  components: {
    StartWork,
  },
});
