


import {
  defineComponent,
  computed,
  onMounted,
  reactive,
} from '@vue/composition-api';

import { useStore } from '@/hooks/useStore';
import ReportModePane from '@/components/Johaisetsu/JohaisetsuMap/components/ReportsView/ReportModePane/index.vue';
import MapMiscInfo from '@/components/Johaisetsu/JohaisetsuMap/components/common/MapMiscInfo.vue';
import { JohaisetsuMtxExt } from '@/models/apis/johaisetsu/johaisetsuMtxsRequest';
import { JohaisetsuReportExt } from '@/models/apis/johaisetsu/johaisetsuReportResponse';
import ManualDownloadLinks from '@/components/Johaisetsu/JohaisetsuMap/components/common/ManualDownloadLinks/index.vue';
import usePaneControl from '@/components/Johaisetsu/JohaisetsuMap/composables/usePaneControl';
import { useJohaisetsuReports } from '../../composables/useJohaisetsuReports';
import { useExtremeMap } from '@/components/Johaisetsu/JohaisetsuMap/composables/useExtremeMap';
import {
  REPORT_LIST_MAX_HEIGHT_RATE,
  REPORT_LIST_MIN_HEIGHT_RATE,
} from '@/components/Johaisetsu/JohaisetsuMap/consts/johaisetsu_map';
import { useWaitSpinner } from '@/components/Johaisetsu/JohaisetsuMap/composables/useWaitSpinner';
import { MiscInfoForCar } from '@/models/johaisetsu/johaisetsuMap';
import { initReportMapKinds } from '@/components/Johaisetsu/JohaisetsuMap/utils/index';
import {
  typeRemarks,
  JOHAISETSU_TYPE_ENSUI_SANPU,
  isJohaisetsuCompanyDefaultUser,
} from '@/lib/johaisetsu/johaisetsuCommonUtil';

interface State {
  selectReportKind: MiscInfoForCar | null;
}

export default defineComponent({
  setup() {
    const store = useStore();
    const userState = store.state.user;
    const shouldShowManualDownloadLinks = computed<boolean>(() => {
      return isJohaisetsuCompanyDefaultUser(userState.johaisetsu_role);
    });
    const state = reactive<State>({
      selectReportKind: null,
    });
    const { paneState } = usePaneControl({
      paneStyleLimitMap: {
        listMinHeightRate: REPORT_LIST_MIN_HEIGHT_RATE,
        listMaxHeightRate: REPORT_LIST_MAX_HEIGHT_RATE,
      },
    });

    const {
      extremeMapRef,
      mapViewState,
      paneStyle,
      loadExtremeMapEssentials,
      forceResizeExtremeMap,
      showJohaisetsuReportLayer,
      removeJohaisetsuReportLayer,
      showJohaisetsuMtxPopup,
      hidePopup,
    } = useExtremeMap(paneState);

    const {
      searchReports,
      selectReport,
      deselectReport,
      getSelectedReport,
      deselectSelectedReports,
    } = useJohaisetsuReports();

    const waitSpinnerState = useWaitSpinner();

    const redrawJohaisetsuReportLayer = (fitToExtent = false) => {
      const selectedReport = getSelectedReport();
      if (!selectedReport) return;

      showJohaisetsuReportLayer(selectedReport, fitToExtent);
    };

    const getReportType = (carKind: string): MiscInfoForCar | null => {
      return typeRemarks[carKind] || null;
    };

    const getJohaisetsuTypeFromReport = (selectedReport: JohaisetsuReportExt): string => {
      const DEFAULT_TYPE = JOHAISETSU_TYPE_ENSUI_SANPU;
      if (!selectedReport.johaisetsu_type) return DEFAULT_TYPE;
      const johaisetsuType = selectedReport.johaisetsu_type;
      const workType = selectedReport.work_type;
      const reportMapKinds = initReportMapKinds();
      const reportMapKindWorkType = reportMapKinds.workType.find(t => t.type === johaisetsuType);
      if (!reportMapKindWorkType) return DEFAULT_TYPE;
      const item = reportMapKindWorkType.items.find(i => i.label === workType);
      return item ? item.id : DEFAULT_TYPE;
    };

    const onClickReport = async(selectedReport: JohaisetsuReportExt) => {
      state.selectReportKind = getReportType(getJohaisetsuTypeFromReport(selectedReport));
      hidePopup();
      // 選択中の報告書をクリックした場合、選択を解除する
      if (deselectReport(selectedReport.id)) {
        removeJohaisetsuReportLayer();
        return;
      }
      waitSpinnerState.showWaitSpinner = true;
      await selectReport(selectedReport.id);
      waitSpinnerState.showWaitSpinner = false;
      redrawJohaisetsuReportLayer(true);
    };

    const onClickMtx = (mtx: JohaisetsuMtxExt) => {
      const isSelected = mtx.isSelected;
      hidePopup();
      mtx.isSelected = !isSelected;
      if (mtx.isSelected) {
        showJohaisetsuMtxPopup(mtx);
      }
      redrawJohaisetsuReportLayer();
    };

    const onAllDeselected = () => {
      hidePopup();
      deselectSelectedReports();
      redrawJohaisetsuReportLayer();
    };

    const paneResizeStopped = () => {
      forceResizeExtremeMap();
    };

    onMounted(async() => {
      waitSpinnerState.showWaitSpinner = true;
      await Promise.all([loadExtremeMapEssentials(), searchReports()]);
      waitSpinnerState.showWaitSpinner = false;
    });

    return {
      mapViewState,
      paneState,
      paneStyle,
      state,
      // ref
      extremeMapRef,
      // computed
      shouldShowManualDownloadLinks,
      // methods
      onClickReport,
      onClickMtx,
      onAllDeselected,
      paneResizeStopped,
    };
  },
  components: {
    ReportModePane,
    ManualDownloadLinks,
    MapMiscInfo,
  },
});
