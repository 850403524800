
import {
  ref,
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
  nextTick,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsuHelper';
import johaisetsuSagyouJoukyouApi from '@/apis/johaisetsu_sagyou_joukyou';
import {
  getTaskForceInfos,
  getJohaisetsuRoadNameInfos,
  isDataReadOnly,
  getDefaultNameForRegularReport,
  onChangeUrlPathParam,
  convHeadersForList,
} from '@/lib/johaisetsu/johaisetsuCommonUtil';
import { CommonHeader,
  SagyouJoukyouDetailRaw,
  JoukyouInfoRaw } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { JohaisetsuSagyouJoukyouRoadName } from '@/models/apis/master/masterResponse';
import { TaskForce } from '@/models/johaisetsu/johaisetsuCommon';
import {
  filterJohaisetsuSagyouJoukyouRoadNameInfoByRole,
  filterJohaisetsuSagyouJoukyouDetailsByRole,
} from '@/lib/johaisetsu/johaisetsuSagyouJoukyouUtil';
import SagyouJoukyouMap from '@/components/Johaisetsu/SagyouJoukyouMap/SagyouJoukyouMap.vue';
import { notifySuccess } from '@/lib/notificationUtil';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import { getKyokuIdByJohaisetsuRole, isJohaisetsuRoleKyoku } from '@/lib/johaisetsuRoleHelper';

interface SagyouJoukyouMapState {
  isReady: boolean;
  isReadOnly: boolean;
  isRequesting: boolean;

  roadNameInfos: JohaisetsuSagyouJoukyouRoadName[];
  taskForces: TaskForce[];
  sagyouJoukyouHeaders: CommonHeader[];
  selectedTaskForce: TaskForce;
  showTaskForceSelect: boolean;
  selectedSagyouJoukyouInfo: JoukyouInfoRaw<SagyouJoukyouDetailRaw> | null;

  showSnapshotConfirmModal: boolean;
  snapshotName: string;
}
export default defineComponent({
  name: 'johaisetsu-sagyou-joukyou-input',
  components: { SagyouJoukyouMap },
  setup() {
    const sagyouJoukyouMap = ref<InstanceType<typeof SagyouJoukyouMap>>();
    const state = reactive<SagyouJoukyouMapState>({
      isReady: false,
      isReadOnly: false,
      isRequesting: false,

      roadNameInfos: [],
      taskForces: [],
      sagyouJoukyouHeaders: [],
      selectedTaskForce: { id: -1, name: '' },
      showTaskForceSelect: false,
      selectedSagyouJoukyouInfo: null,

      showSnapshotConfirmModal: false,
      snapshotName: '',
    });

    const store = useStore();
    const userState = store.state.user;
    const johaisetsuRole = computed<string>(() => {
      return getKyokuIdByJohaisetsuRole(userState.johaisetsu_role);
    });
    const { route, router } = useRoute();
    const taskForceId = computed<string>(() => {
      return route.value.params.taskForceId;
    });
    const headerId = computed<string>(() => {
      return route.value.params.headerId;
    });
    const canSendReport = computed<boolean>(() => {
      return isJohaisetsuRoleKyoku(userState.johaisetsu_role);
    });
    const onSelectedTaskForceChange = (taskForceId: number) => {
      onChangeUrlPathParam(router, route.value.name || '', taskForceId, 'current');
    };
    const onSelectedHeaderChange = (headerId: string) => {
      onChangeUrlPathParam(router, route.value.name || '', taskForceId.value, headerId);
    };
    const reloadData = async() => {
      state.isReady = false;
      await getSagyouJoukyouHeaders();
      await getSelectedSagyouJoukyouInfo();
      state.isReady = true;
      nextTick(() => {
        drawMap();
      });
    };
    const getSagyouJoukyouHeaders = async() => {
      const { data: sagyouJoukyouHeaders } = await johaisetsuSagyouJoukyouApi.index({
        taskForceId: state.selectedTaskForce.id,
      });
      state.sagyouJoukyouHeaders = convHeadersForList(state.selectedTaskForce, sagyouJoukyouHeaders, johaisetsuRole.value);
    };
    const getSelectedSagyouJoukyouInfo = async() => {
      const { data: selectedSagyouJoukyouInfo } =
        await johaisetsuSagyouJoukyouApi.show(
          {
            taskForceId: state.selectedTaskForce.id,
            headerId: headerId.value,
          },
          {
            with_kanri_block: 1,
          },
        );

      selectedSagyouJoukyouInfo.details = filterJohaisetsuSagyouJoukyouDetailsByRole(
        selectedSagyouJoukyouInfo.details,
        johaisetsuRole.value,
      );
      state.selectedSagyouJoukyouInfo = selectedSagyouJoukyouInfo;
    };

    const sagyouJoukyouInfoDetailsSort = (a: SagyouJoukyouDetailRaw, b: SagyouJoukyouDetailRaw): number => {
      // https://git.kokudo-digital.com/kokudo-digital/land-rt-streaming-ap2/src/branch/master/src/assets/src/lib/johaisetsu/johaisetsuSagyouJoukyouUtil.ts#L241-L250 に同様の処理あり
      return (a.kanri_block?.block_code ?? '0') < (b.kanri_block?.block_code ?? '0') ? -1 : 1;
    };

    const drawMap = () => {
      if (!state.selectedSagyouJoukyouInfo || !state.selectedSagyouJoukyouInfo.header) { return; }
      const mapUpdateInfos = state.selectedSagyouJoukyouInfo.details
        .sort(sagyouJoukyouInfoDetailsSort)
        .filter(e => {
          return e.kanri_block?.is_editable;
        })
        .map(e => {
          if (e.kanri_block?.place_type === 'main_line') {
            return {
              placeType: e.kanri_block.place_type,
              blockCode: e.kanri_block.block_code,
              name: '', // 使わない
              status: e.status?.toString() || '',
            };
          } else {
            return {
              placeType: e.kanri_block?.place_type || '',
              blockCode: '', // 使わない
              name: e.kanri_block?.start_place_name?.replace(/入口|出口/, '') || '',
              status: e.status?.toString() || '',
            };
          }
        });

      if (sagyouJoukyouMap.value) {
        // 同一名称の入口/出口が複数個ある場合、順番的に後ろ側にある要素のstatusが0だと画面表示上作業が進んでないことになってしまう.
        // したがって、statusが0の場合、同一名称でstatusが0以外のものが無いか探し、あればその値を使う.
        mapUpdateInfos.forEach(e => {
          if (e.status !== '0') { return; }
          const referenceInfos = mapUpdateInfos.filter(x => {
            return e.placeType === x.placeType && e.blockCode === x.blockCode && e.name === x.name && x.status !== '0';
          });
          if (referenceInfos.length === 0) { return; }
          // 見つかったもののうち常に最後のものを利用するのでよいのかどうか??
          // statusが一番大きいものにするなどでもよい気がするが...
          e.status = referenceInfos[referenceInfos.length - 1].status;
        });
        sagyouJoukyouMap.value.updateMapElements(mapUpdateInfos);
      }
    };
    const openSnapshotConfirmModal = () => {
      state.snapshotName = getDefaultNameForRegularReport();
      state.showSnapshotConfirmModal = true;
    };
    const saveSnapshot = async() => {
      if (state.isRequesting) { return; }
      if (!state.snapshotName) { return; }
      state.isRequesting = true;
      await johaisetsuSagyouJoukyouApi.createSnapshot(
        state.selectedTaskForce.id,
        { snapshot_name: state.snapshotName },
      );
      notifySuccess('', '定時報告を送信しました');
      state.showSnapshotConfirmModal = false;
      state.isRequesting = false;
      await reloadData();
    };
    const cancelSnapshot = () => {
      state.showSnapshotConfirmModal = false;
    };

    onMounted(async() => {
      await Promise.all([
        waitForUserAndMasters(),
        waitForJohaisetsuMasters(),
      ]);
      redirectIfNoAbility(userState, route.value);

      const roadNameInfos = getJohaisetsuRoadNameInfos();
      state.roadNameInfos = filterJohaisetsuSagyouJoukyouRoadNameInfoByRole(
        roadNameInfos, johaisetsuRole.value);

      const { taskForces, selectedTaskForce } = getTaskForceInfos(taskForceId.value);
      state.taskForces = taskForces;
      state.selectedTaskForce = selectedTaskForce;

      await getSagyouJoukyouHeaders();
      await getSelectedSagyouJoukyouInfo();

      state.isReadOnly = isDataReadOnly(
        state.selectedTaskForce,
        state.selectedSagyouJoukyouInfo?.header,
        johaisetsuRole.value,
      );

      state.isReady = true;
      nextTick(() => {
        drawMap();
      });
    });
    return {
      ...toRefs(state),
      // refes
      sagyouJoukyouMap,
      // computed
      johaisetsuRole,
      taskForceId,
      headerId,
      canSendReport,
      // methods
      onSelectedTaskForceChange,
      onSelectedHeaderChange,
      reloadData,
      getSagyouJoukyouHeaders,
      getSelectedSagyouJoukyouInfo,
      drawMap,
      openSnapshotConfirmModal,
      saveSnapshot,
      cancelSnapshot,
    };
  },
});
