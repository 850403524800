


import {
  defineComponent,
} from '@vue/composition-api';
import SettouSagyouCommon from '@/components/Sp/SettouSagyouReport/SettouSagyouCommon/index.vue';
import { ABILITY_JOSETSU_INPUT } from '@/consts/ability';
import { JOHAISETSU_TYPE_GROUP_JOSETSU_SAGYOU } from '@/consts/johaisetsu_car';
import { provideSettouSagyou } from '@/components/Sp/SettouSagyouReport/composables/useSettouSagyou';

export interface SettouSagyouJosetsuState {
  ability: number;
  johaisetsuTypeGroup: string;
  johaisetsuTypeGroupName: string;
}
export default defineComponent({
  name: 'sp-settou-sagyou-josetsu',
  components: { SettouSagyouCommon },
  setup() {
    const state = provideSettouSagyou({
      ability: ABILITY_JOSETSU_INPUT,
      johaisetsuTypeGroup: JOHAISETSU_TYPE_GROUP_JOSETSU_SAGYOU,
      johaisetsuTypeGroupName: '除雪作業',
    });
    return state;
  },
});
