import {
  ABILITY_TOUKETSU_BOUSHI_INPUT,
  ABILITY_YUSETSU_INPUT,
  ABILITY_KISEI_INPUT,
  ABILITY_JOSETSU_INPUT,
  ABILITY_HAISETSU_INPUT,
  ABILITY_SETTOU_PATROL_REPORT_INPUT,
  ABILITY_ENSUI_PLANT_STOCK_INPUT,
  ABILITY_SETTOU_ONSITE_MANAGEMENT_REPORT_INPUT,
  ABILITY_CLEANING_REPORT,
  ABILITY_CLEANING_MAP,
} from '@/consts/ability';
import { isRoleHonsha, isRoleKyoku, isRoleDriver } from '@/lib/roleHelper';
import { isJohaisetsuRoleHonsha, isJohaisetsuRoleKyoku, isJohaisetsuRoleDriver } from '@/lib/johaisetsuRoleHelper';
import { Lov } from '@/models/apis/master/masterResponse';

export function filterDefaultPagesByJohaisetsuRole(defaultPagesOrig: Lov[], role: string | null): Lov[] {
  const defaultPages = defaultPagesOrig.filter(e => !e.key.startsWith('cleaning_'));
  let ret = defaultPages.filter(e => e.key.indexOf('johaisetsu_') === -1);
  if (!role) { return ret; }

  if (
    isJohaisetsuRoleHonsha(role) ||
    isJohaisetsuRoleKyoku(role)
  ) {
    ret = defaultPages.filter(e => e.key.indexOf('johaisetsu_sp_') === -1);
  } else if (isJohaisetsuRoleDriver(role)) {
    ret = defaultPages.filter(e => e.key.indexOf('johaisetsu_sp_') !== -1);
  }
  return ret;
}
export function filterDefaultPagesByCleaningRole(defaultPagesOrig: Lov[], role: string | null): Lov[] {
  const defaultPages = defaultPagesOrig.filter(e => !e.key.startsWith('johaisetsu_'));
  let ret = defaultPages.filter(e => e.key.indexOf('cleaning_') === -1);
  if (!role) { return ret; }

  if (
    isRoleHonsha(role) ||
    isRoleKyoku(role)
  ) {
    ret = defaultPages.filter(e => e.key.indexOf('cleaning_sp_') === -1);
  } else if (isRoleDriver(role)) {
    ret = defaultPages.filter(e => e.key.indexOf('cleaning_sp_') !== -1);
  }
  return ret;
}
export function filterDefaultPagesByAbilityIds(defaultPages: Lov[], abilityIds: number[]): Lov[] {
  let ret = defaultPages;
  if (!abilityIds.includes(ABILITY_TOUKETSU_BOUSHI_INPUT)) {
    ret = ret.filter(e => e.key !== 'johaisetsu_sp_touketsu_boushi');
  }
  if (!abilityIds.includes(ABILITY_YUSETSU_INPUT)) {
    ret = ret.filter(e => e.key !== 'johaisetsu_sp_yusetsu');
  }
  if (!abilityIds.includes(ABILITY_KISEI_INPUT)) {
    ret = ret.filter(e => e.key !== 'johaisetsu_sp_kisei');
  }
  if (!abilityIds.includes(ABILITY_JOSETSU_INPUT)) {
    ret = ret.filter(e => e.key !== 'johaisetsu_sp_josetsu');
  }
  if (!abilityIds.includes(ABILITY_HAISETSU_INPUT)) {
    ret = ret.filter(e => e.key !== 'johaisetsu_sp_haisetsu');
  }
  if (!abilityIds.includes(ABILITY_SETTOU_PATROL_REPORT_INPUT)) {
    ret = ret.filter(e => e.key !== 'johaisetsu_sp_settou_patrol_report');
  }
  if (!abilityIds.includes(ABILITY_ENSUI_PLANT_STOCK_INPUT)) {
    ret = ret.filter(e => e.key !== 'johaisetsu_sp_ensui_plant_stock_report');
  }
  if (!abilityIds.includes(ABILITY_SETTOU_ONSITE_MANAGEMENT_REPORT_INPUT)) {
    ret = ret.filter(e => e.key !== 'johaisetsu_sp_settou_onsite_management_report');
  }
  if (!abilityIds.includes(ABILITY_CLEANING_MAP)) {
    ret = ret.filter(e => e.key !== 'cleaning_map');
  }
  if (!abilityIds.includes(ABILITY_CLEANING_REPORT)) {
    ret = ret.filter(e => e.key !== 'cleaning_sp_sagyou');
  }
  return ret;
}
