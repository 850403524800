

























import {
  defineComponent,
  toRefs,
  reactive,
  ref,
} from '@vue/composition-api';
import axios from 'axios';
import { useStore } from '@/hooks/useStore';
import { LocalStorageActionTypes } from '@/store/modules/localStorage';
import { UserActionTypes } from '@/store/modules/user';

interface LoginState {
 loginForm: {
    username: string;
    password: string;
  };
  showErrorMessage: boolean;
}

export default defineComponent({
  name: 'login',
  setup() {
    const state = reactive<LoginState>({
      loginForm: {
        username: '',
        password: '',
      },
      showErrorMessage: false,
    });
    // methods
    // iOSのchromeだとautofillでchangeが効かないぽいのでがんばる
    const textInputUsername = ref<HTMLInputElement>();
    const textInputPassword = ref<HTMLInputElement>();

    const ensureLoginFormValuesSynced = () => {
      const username = textInputUsername.value?.value;
      const password = textInputPassword.value?.value;
      state.loginForm.username = username || '';
      state.loginForm.password = password || '';
    };
    const store = useStore();
    const tryLogin = async() => {
      state.showErrorMessage = false;
      ensureLoginFormValuesSynced();

      // loginしようとするときは明示的に消す.
      // expiredなトークンを送り続けるとログインできないので.
      delete axios.defaults.headers['Authorization'];

      try {
        const res = await store.dispatch(UserActionTypes.LOGIN, state.loginForm);
        await store.dispatch(LocalStorageActionTypes.SET, {
          key: 'serverToken',
          val: res['access_token'],
        });
        const defaultPage = res.user.default_page;
        const redirectMappings: Record<string, string> = {
          'sp_top': '/sp',
          'johaisetsu_sp_touketsu_boushi': '/sp/touketsu-boushi',
          'johaisetsu_sp_yusetsu': '/sp/yusetsu',
          'johaisetsu_sp_kisei': '/sp/kisei',
          'johaisetsu_sp_josetsu': '/sp/josetsu',
          'johaisetsu_sp_haisetsu': '/sp/haisetsu',
          'johaisetsu_sp_settou_patrol_report': '/sp/settou-patrol-report',
          'johaisetsu_sp_ensui_plant_stock_report': '/sp/ensui-plant-stock-report',
          'johaisetsu_sp_settou_onsite_management_report': '/sp/settou-onsite-management-report',
          'cleaning_map': '/cleaning',
          'cleaning_sp_report': '/sp/cleaning-report',
        };

        let redirectUrl;

        if (res.user.is_johaisetsu_only) {
          redirectUrl = '/settou-message-board';
        } else if (res.user.is_cleaning_only) {
          redirectUrl = '/cleaning-map';
        } else {
          redirectUrl = redirectMappings[defaultPage] || '/';
        }
        location.href = redirectUrl;
      } catch (e) {
        state.showErrorMessage = true;
      }
    };

    return {
      ...toRefs(state),
      // refs
      textInputUsername,
      textInputPassword,
      // methods
      ensureLoginFormValuesSynced,
      tryLogin,
    };
  },
});
