import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  JohaisetsuCarCreateOrUpdateParams,
} from '@/models/apis/johaisetsu/johaisetsuCarRequest';
import {
  JohaisetsuCarShowResponse,
  JohaisetsuCarCreateOrUpdateResponse,
  JohaisetsuCarsGetResponse,
} from '@/models/apis/johaisetsu/johaisetsuCarResponse';

export default {
  // SP画面から新規作成or更新
  createOrUpdate(params: JohaisetsuCarCreateOrUpdateParams): Promise<AxiosResponse<JohaisetsuCarCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_cars'), params || {});
  },
  async getMyJohaisetsuCar(): Promise<JohaisetsuCarShowResponse | null> {
    const { data } = await axios.get<JohaisetsuCarShowResponse | null>(utils.getApiUrl('/johaisetsu_cars/me'));
    return data;
  },
  getJohaisetsuCars(): Promise<AxiosResponse<JohaisetsuCarsGetResponse>> {
    return axios.get(utils.getApiUrl('/johaisetsu_cars'));
  },
};
