import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  JohaisetsuHanMastersIndexResponse,
  JohaisetsuHanMastersGetByUserResponse,
} from '@/models/apis/johaisetsu/johaisetsuHanMastersResponse';

export default {
  index(): Promise<AxiosResponse<JohaisetsuHanMastersIndexResponse>> {
    return axios.get(utils.getApiUrl('/johaisetsu_han_masters'));
  },
  getDataBasedOnPermissions(): Promise<AxiosResponse<JohaisetsuHanMastersGetByUserResponse>> {
    return axios.get(utils.getApiUrl('/johaisetsu_han_masters/get_data_based_on_permissions'));
  },
};
