
import {
  ref,
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
  nextTick,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsuHelper';
import { dtFormat } from '@/lib/dateHelper';
import johaisetsuJutenSanpuJoukyouApi from '@/apis/johaisetsu_juten_sanpu_joukyou';
import { notifySuccess } from '@/lib/notificationUtil';
import {
  getTaskForceInfos,
  isDataReadOnly,
  onChangeUrlPathParam,
} from '@/lib/johaisetsu/johaisetsuCommonUtil';
import {
  prepareBlocks,
  getTableInputInfo,
  updateStatusDependentParams,
  getClearedErrorMap,
  checkItems,
  deconvProps,
  DATA_TYPE_JUTEN_SANPU,
} from '@/lib/johaisetsu/johaisetsuEnsuiJutenKaradanpuCommonUtil';
import { ABILITY_ENSUI_JUTEN_KARADANPU_MAP } from '@/consts/ability';
import {
  JUTEN_SANPU_STATUS_NONE,
  JUTEN_SANPU_STATUS_SANPU_CHU,
  JUTEN_SANPU_STATUS_SANPU_KANRYOU,
} from '@/consts/johaisetsu';
import DateTimeInput from '@/components/lib/DateTimeInput.vue';
import { CommonHeader,
  JoukyouInfoRaw,
  StatusMap,
  JohaisetsuJoukyouDetail,
  SanpuBlock } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { TaskForce,
  JoukyouInputMap,
  TableCommonInfo,
  SagyouJoukyouInputError,
  JoukyouTableInputRow } from '@/models/johaisetsu/johaisetsuCommon';
import { Ability } from '@/models/apis/user/userResponse';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import { getKyokuIdByJohaisetsuRole } from '@/lib/johaisetsuRoleHelper';

const api = johaisetsuJutenSanpuJoukyouApi;
const dataType = DATA_TYPE_JUTEN_SANPU;

interface JutenSanpuJoukyouInputState {
  isReady: boolean;
  isReadOnly: boolean;
  isRequesting: boolean;

  blocks: SanpuBlock[];
  taskForces: TaskForce[];
  joukyouHeaders: CommonHeader[];
  statuses: StatusMap[];

  showTaskForceSelect: boolean;
  selectedTaskForce: TaskForce;
  selectedJoukyouInfo: JoukyouInfoRaw<JohaisetsuJoukyouDetail> | null;
  selectedBlockId: number;

  blockImgSrc: string | null;
  blockNameDisp: string | null;
  tableCommonInfo: TableCommonInfo;
  tableInputRows: JoukyouTableInputRow[];

  datePickerXOffsetCalcParent?: HTMLDivElement | null;
  datePickerYOffsetCalcParent?: HTMLDivElement | null;
  errorMap: Record<string, SagyouJoukyouInputError>;
  hasError: boolean;

  statusMap: Record<string, number>;
}
export default defineComponent({
  name: 'johaisetsu-juten-sanpu-joukyou-input',
  components: { DateTimeInput },
  setup() {
    const state = reactive<JutenSanpuJoukyouInputState>({
      isReady: false,
      isReadOnly: false,
      isRequesting: false,

      blocks: [],
      taskForces: [],
      joukyouHeaders: [],
      statuses: [],

      showTaskForceSelect: false,
      selectedTaskForce: { id: -1, name: '' },
      selectedJoukyouInfo: null,
      selectedBlockId: 0,

      blockImgSrc: null,
      blockNameDisp: null,
      tableCommonInfo: {} as TableCommonInfo,
      tableInputRows: [],

      datePickerXOffsetCalcParent: null,
      datePickerYOffsetCalcParent: null,
      errorMap: {},
      hasError: false,

      statusMap: {
        none: JUTEN_SANPU_STATUS_NONE,
        sanpuChu: JUTEN_SANPU_STATUS_SANPU_CHU,
        sanpuKanryou: JUTEN_SANPU_STATUS_SANPU_KANRYOU,
      },
    });

    const store = useStore();
    const userState = store.state.user;
    const johaisetsuRole = computed<string>(() => {
      return getKyokuIdByJohaisetsuRole(userState.johaisetsu_role);
    });
    const abilityMap = computed<Record<number, Ability>>(() => {
      return userState.abilityMap;
    });
    const { route, router } = useRoute();
    const taskForceId = computed<string>(() => {
      return route.value.params.taskForceId;
    });
    const headerId = computed<string>(() => {
      return route.value.params.headerId;
    });
    const canUseJutenMap = computed<boolean>(() => {
      return !!abilityMap.value[ABILITY_ENSUI_JUTEN_KARADANPU_MAP];
    });
    const tableSanpuKanryouNum = computed<number | null>(() => {
      if (!state.selectedBlockId) { return null; }

      return state.tableInputRows.filter(e => {
        return e.inputMap.status === state.statusMap.sanpuKanryou;
      }).reduce((acc) => {
        return acc + 1;
      }, 0);
    });
    const main2Table = ref<HTMLDivElement>();
    const main2 = ref<HTMLDivElement>();
    const updateRefs = () => {
      nextTick(() => {
        state.datePickerXOffsetCalcParent = main2Table.value;
        state.datePickerYOffsetCalcParent = main2.value;
      });
    };
    const onStatusChange = (inputMap: JoukyouInputMap) => {
      updateStatusDependentParams(
        inputMap,
        window.johaisetsuMaster.jutenSanpuStatusMap,
        dataType,
      );
    };
    const onBlockChange = () => {
      if (!state.selectedBlockId) { return; }
      const block = state.blocks.find(e => e.id === state.selectedBlockId);
      if (!block) { return; }

      const tableInputInfo = getTableInputInfo(
        state.selectedJoukyouInfo,
        block,
        window.johaisetsuMaster.jutenSanpuStatusMap,
        dataType,
      );
      if (tableInputInfo) {
        state.blockImgSrc = tableInputInfo.blockImgSrc;
        state.blockNameDisp = tableInputInfo?.blockNameDisp || null;
        state.tableCommonInfo = tableInputInfo.tableCommonInfo;
        state.tableInputRows = tableInputInfo.tableInputRows;
      }
      resetErrorMap();
      updateRefs();
    };
    const onSelectedTaskForceChange = (taskForceId: number) => {
      onChangeUrlPathParam(router, route.value.name || '', taskForceId, 'current');
    };
    const resetErrorMap = () => {
      const items = state.tableInputRows;
      state.errorMap = getClearedErrorMap(items);
    };
    const saveItems = async() => {
      if (state.isRequesting) { return; }

      state.hasError = false;
      let items = state.tableInputRows;
      if (!checkItems(items, state.errorMap, dataType)) {
        state.hasError = true;
        return;
      }

      state.isRequesting = true;
      const reqItems = items.map(e => {
        return deconvProps(e.inputMap, dataType);
      });
      await api.updateDetails(
        state.selectedTaskForce.id,
        { details: reqItems },
      );
      notifySuccess('', 'データを保存しました');
      // 再取得
      await getSelectedJoukyouInfo();
      state.isRequesting = false;
      onBlockChange();
    };
    const getSelectedJoukyouInfo = async() => {
      const { data: selectedJoukyouInfo } = await api.show({
        taskForceId: state.selectedTaskForce.id,
        headerId: headerId.value,
      });
      state.selectedJoukyouInfo = selectedJoukyouInfo;
      state.blocks = prepareBlocks(
        state.selectedJoukyouInfo,
        johaisetsuRole.value,
        dataType,
      );
    };
    const showMapPage = () => {
      const routeObj = {
        name: 'JohaisetsuJutenSanpuJoukyouMap',
        params: {
          taskForceId: taskForceId.value.toString(),
          headerId: headerId.value,
        },
      };
      const obj = router.resolve(routeObj);
      window.open(obj.href, '_blank');
    };
    onMounted(async() => {
      state.isRequesting = true;
      await Promise.all([
        waitForUserAndMasters(),
        waitForJohaisetsuMasters(),
      ]);
      redirectIfNoAbility(userState, route.value);

      state.statuses = window.johaisetsuMaster.jutenSanpuStatuses;

      const { taskForces, selectedTaskForce } = getTaskForceInfos(taskForceId.value);
      state.taskForces = taskForces;
      state.selectedTaskForce = selectedTaskForce;

      // 待たなくていい
      api.index({
        taskForceId: state.selectedTaskForce.id,
      }).then(({ data: joukyouHeaders }) => {
        state.joukyouHeaders = joukyouHeaders;
      });

      await getSelectedJoukyouInfo();
      state.isRequesting = false;

      state.isReadOnly = isDataReadOnly(
        state.selectedTaskForce,
        state.selectedJoukyouInfo?.header,
        johaisetsuRole.value,
      );

      if (!state.selectedBlockId) {
        state.selectedBlockId = state.blocks[0].id;
        onBlockChange();
      }

      state.isReady = true;
    });
    return {
      ...toRefs(state),
      // refes
      main2Table,
      main2,
      // // computed
      johaisetsuRole,
      taskForceId,
      headerId,
      canUseJutenMap,
      tableSanpuKanryouNum,
      // methods
      updateRefs,
      onStatusChange,
      onBlockChange,
      onSelectedTaskForceChange,
      resetErrorMap,
      saveItems,
      getSelectedJoukyouInfo,
      showMapPage,
      dtFormat,
    };
  },
});
