


import {
  defineComponent,
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { UserActionTypes } from '@/store/modules/user';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsuHelper';
import {
  timeDifferenceInSeconds,
  secondsToTimeInteger,
  timeInteger,
} from '@/lib/dateTimeHelper';
import useSpeech from '@/composables/useSpeech';
import johaisetsuCarApi from '@/apis/johaisetsu_car';
import johaisetsuReportApi from '@/apis/johaisetsu_report';
import johaisetsuMtxApi from '@/apis/johaisetsu_mtx';
import { enableNoSleep, disableNoSleep } from '@/lib/noSleepUtil';
import { JOHAISETSU_TYPE_GROUP_TOUKETSU_BOUSHI_SAGYOU } from '@/consts/johaisetsu_car';
import { Position, GeolocationPositionError } from '@/models/index';
import { SettouSagyouCommonState } from '@/models/johaisetsu/spJohaisetsuReport';
import { Ability } from '@/models/apis/user/userResponse';
import { dtFormat, ensureDate } from '@/lib/dateHelper';
import { JohaisetsuMtx } from '@/models/apis/johaisetsu/johaisetsuMtxsRequest';
import { JohaisetsuReport } from '@/models/apis/johaisetsu/johaisetsuReportResponse';
import { JohaisetsuCar, JohaisetsuCarShowResponse } from '@/models/apis/johaisetsu/johaisetsuCarResponse';
import {
  STATUS_RUNNING,
  STATUS_WORKING,
  STATUS_STOPPED,
  CAR_NEW_DATA,
  CAR_OLD_DATA,
  WORK_TYPE_NAME_DEFAULT,
  statusDispMap,
  updateJohaisetsuStart,
  updateJohaisetsuEnd,
  notifyWorkElapsedTimeSpeech,
  createOrUpdateJohaisetsuCar,
} from '@/lib/johaisetsu/johaisetsuReportUtil';
import {
  initJohaisetsuReportState,
  initJohaisetsuReportExt,
  toJohaisetsuReportExt,
} from '@/components/Sp/SettouSagyouReport/SettouSagyouCommon/utils/index';

import BaseDeparture from '@/components/Sp/SettouSagyouReport/SettouSagyouCommon/BaseDeparture/index.vue';
import AfterDeparture from '@/components/Sp/SettouSagyouReport/SettouSagyouCommon/AfterDeparture/index.vue';
import SaveWorkSituation from '@/components/Sp/SettouSagyouReport/SettouSagyouCommon/SaveWorkSituation/index.vue';
import Working from '@/components/Sp/SettouSagyouReport/SettouSagyouCommon/Working/index.vue';
import Pause from '@/components/Sp/SettouSagyouReport/SettouSagyouCommon/Pause/index.vue';
import BaseArrival from '@/components/Sp/SettouSagyouReport/SettouSagyouCommon/BaseArrival/index.vue';

export default defineComponent({
  name: 'sp-settou-sagyou-common',
  components: {
    BaseDeparture,
    AfterDeparture,
    SaveWorkSituation,
    Working,
    Pause,
    BaseArrival,
  },
  props: {
    ability: {
      type: Number,
      required: true,
    },
    johaisetsuTypeGroup: {
      type: String,
      required: true,
    },
    johaisetsuTypeGroupName: {
      type: String,
      required: true,
    },
    shouldSaveJohaisetsuMtx: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { speechSynthesisVoices, doSpeech } = useSpeech();
    const state = reactive<SettouSagyouCommonState>(initJohaisetsuReportState());
    const store = useStore();
    const userState = store.state.user;
    const abilityMap = computed<Record<number, Ability>>(() => {
      return userState.abilityMap;
    });
    const currentComponent = computed<string>(() => {
      if (state.isWorkSituationEditing) {
        return 'SaveWorkSituation';
      } else if (state.isBacking) {
        return 'BaseArrival';
      } else if (state.isSuspending) {
        return 'Pause';
      } else if (state.isWorking) {
        return 'Working';
      } else if (state.isMoving) {
        return 'AfterDeparture';
      } else {
        return 'BaseDeparture';
      }
    });
    const displayName = computed<string>(() => {
      return userState.display_name;
    });
    const hasError = computed<boolean>(() => {
      return Object.values(state.errorObj).includes(true);
    });
    const isGettingCurrentLocation = computed<boolean>(() => {
      return !!state.geolocationWatchHandler;
    });
    const showSanpuNum = computed<boolean>(() => {
      return props.johaisetsuTypeGroup === JOHAISETSU_TYPE_GROUP_TOUKETSU_BOUSHI_SAGYOU;
    });
    const workElapsedTimeInt = computed<number>(() => {
      if (!state.workStartTime || !state.currentTime) return 0;
      const workElapsedTimeSec = timeDifferenceInSeconds(state.workStartTime, state.currentTime);
      return secondsToTimeInteger(workElapsedTimeSec);
    });
    const johaisetsuHan = computed<string>(() => {
      if (!userState.johaisetsu_han || userState.johaisetsu_han.length === 0) return '';
      return userState.johaisetsu_han[0].name;
    });
    const isNewData = computed<number>(() => {
      return state.johaisetsuReportId <= 0 ? CAR_NEW_DATA : CAR_OLD_DATA;
    });
    const showWorkTime = computed<boolean>(() => {
      return state.isWorking || state.isSuspending || state.isBacking;
    });

    const clearGeolocationErrors = () => {
      state.geolocationErrors = [];
    };
    const clearGeolocationWatch = () => {
      if (!state.geolocationWatchHandler) return;
      navigator.geolocation.clearWatch(state.geolocationWatchHandler);
      state.geolocationWatchHandler = null;
      clearGeolocationErrors();
      state.currentLocation = null;
    };
    const clearTimers = () => {
      if (state.johaisetsuCarUpdateTimer) {
        clearInterval(state.johaisetsuCarUpdateTimer);
      }
      if (state.workElapsedTimeNotifyTimer) {
        clearInterval(state.workElapsedTimeNotifyTimer);
      }
      if (state.johaisetsuMtxStoreTimer) {
        clearInterval(state.johaisetsuMtxStoreTimer);
      }
      if (state.johaisetsuMtxCreateTimer) {
        clearInterval(state.johaisetsuMtxCreateTimer);
      }
      state.johaisetsuCarUpdateTimer = null;
      state.workElapsedTimeNotifyTimer = null;
      state.johaisetsuMtxStoreTimer = null;
      state.johaisetsuMtxCreateTimer = null;
    };
    const setCurrentLocation = (position: Position) => {
      const { latitude, longitude } = position.coords;
      // 小数点第6位まで
      const base = 1000000;
      state.currentLocation = { lat: Math.floor(latitude * base) / base, lon: Math.floor(longitude * base) / base };
      // 取得に成功したらエラーを消す
      clearGeolocationErrors();
    };
    const saveContentAndRoad = async(report: JohaisetsuReport, workType: string) => {
      state.isRequesting = true;
      if (!report) {
        showErrorMsg('保存に失敗しました。再度操作を行ってください');
      }
      setJohaisetsuReport(report);
      state.selectedJohaisetsuType = workType;
      state.isWorkSituationEditing = false;
      state.isRequesting = false;
    };
    const showErrorMsg = (errMsg: string) => {
      state.errorModalMsg = errMsg;
      state.showErrorModal = true;
    };
    const onGeolocationError = (evt: GeolocationPositionError) => {
      console.error('geolocation error', evt);
      const code = evt.code;
      // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
      // 1 PERMISSION_DENIED
      // 2 POSITION_UNAVAILABLE
      // 3 TIMEOUT
      if (code === 1) {
        // 位置情報の利用が許可されていない場合
        stopGettingCurrentLocation();
        state.showGeolocationPermissionErrorModal = true;
      } else {
        // 位置情報の取得に失敗した場合
        // エラーの内容が1(PERMISSION_DENIED)以外であれば、
        // 位置情報の利用自体は許可されているはず.
        state.geolocationErrors.push(evt);
        if (state.geolocationErrors.length > 5) {
          // エラーが何回か続いたら、画面下にエラーメッセージ出す
          state.showGeolocationGeneralErrorMsg = true;
        }
      }
    };
    const startGettingCurrentLocation = () => {
      clearGeolocationWatch();
      state.geolocationWatchHandler = navigator.geolocation.watchPosition(
        setCurrentLocation,
        onGeolocationError,
        state.geolocationOpts,
      );
    };
    const stopGettingCurrentLocation = async() => {
      await stopAll();
      clearGeolocationWatch();
    };
    const initJohaisetsuReport = () => {
      state.johaisetsuReportId = -1;
      state.baseDepartureTime = null;
      state.workStartTime = null;
      state.workEndTime = null;
      state.johaisetsuCar.report = initJohaisetsuReportExt();
    };
    const updateJohaisetsuCar = async() => {
      if (!state.currentLocation) return;
      if (!state.selectedJohaisetsuType) return;
      const obj = {
        currentLocation: state.currentLocation,
        status: state.status,
        statusDisp: statusDispMap[state.status],
        selectedJohaisetsuType: state.selectedJohaisetsuType,
      };
      const data = await createOrUpdateJohaisetsuCar(obj, isNewData.value);
      state.johaisetsuCar.id = data.id;
      state.johaisetsuCar.deviceId = data.device_id;
    };
    const storeJohaisetsuMtx = () => {
      if (!state.currentLocation) return;
      if (!state.johaisetsuCar.deviceId) return;
      const now = new Date();
      if (state.lastJohaisetsuMtxTs) {
        const thres = 1000; // milliseconds
        const diffMSec = now.getTime() - state.lastJohaisetsuMtxTs.getTime();
        if (diffMSec < thres) return;
      }
      const { lat, lon } = state.currentLocation;
      const obj: JohaisetsuMtx = {
        device_id: state.johaisetsuCar.deviceId,
        ts: now,
        lat: lat,
        lon: lon,
        data_type: state.selectedJohaisetsuType,
        johaisetsu_report_id: state.johaisetsuReportId,
        status: state.status,
      };
      if (showSanpuNum.value && state.johaisetsuCar.report.sanpuNum) {
        obj.data_value = parseInt(state.johaisetsuCar.report.sanpuNum?.toString());
      }
      state.johaisetsuMtxs.push(obj);
      state.lastJohaisetsuMtxTs = now;
    };
    const saveJohaisetsuMtxs = async() => {
      // 値として取れているものが10個以上あったら送る
      const thres = 10;
      if (state.johaisetsuMtxs.length < thres) return;

      const johaisetsuMtxs = state.johaisetsuMtxs.splice(0);
      const reqObj = { johaisetsu_mtxs: johaisetsuMtxs };
      await johaisetsuMtxApi.createJohaisetsuMTXs(reqObj);
    };
    const setJohaisetsuReport = (johaisetsuReport: JohaisetsuReport) => {
      const reportExt = toJohaisetsuReportExt(johaisetsuReport);
      state.johaisetsuCar.report = reportExt;
      state.johaisetsuReportId = johaisetsuReport.id;
      state.baseDepartureTime = ensureDate(reportExt.baseDepartureTs);
      state.workStartTime = ensureDate(reportExt.startTs);
      state.workEndTime = ensureDate(reportExt.endTs);
    };
    const notifyWorkElapsedTime = () => {
      // 現在時刻の更新
      state.currentTime = new Date();
      notifyWorkElapsedTimeSpeech(workElapsedTimeInt.value, WORK_TYPE_NAME_DEFAULT, doSpeech);
    };
    const restartJohaisetsuCarUpdateInterval = async() => {
      if (state.johaisetsuCarUpdateTimer) {
        clearInterval(state.johaisetsuCarUpdateTimer);
      }
      state.johaisetsuCarUpdateTimer = setInterval(updateJohaisetsuCar, state.johaisetsuCarUpdateTimerSec * 1000, state.status);
    };
    const restartWorkElapsedTimeNotifyInterval = () => {
      state.workStartTime = new Date();
      if (state.workElapsedTimeNotifyTimer) {
        clearInterval(state.workElapsedTimeNotifyTimer);
      }
      state.workElapsedTimeNotifyTimer = setInterval(notifyWorkElapsedTime, state.workElapsedTimeNotifyTimerSec * 1000);
    };
    const restartJohaisetsuMtxUpdateInterval = () => {
      if (!props.shouldSaveJohaisetsuMtx) return;

      state.johaisetsuMtxs = [];
      if (state.johaisetsuMtxStoreTimer) {
        clearInterval(state.johaisetsuMtxStoreTimer);
      }
      if (state.johaisetsuMtxCreateTimer) {
        clearInterval(state.johaisetsuMtxCreateTimer);
      }
      state.johaisetsuMtxStoreTimer = setInterval(storeJohaisetsuMtx, state.johaisetsuMtxStoreTimerSec * 1000);
      state.johaisetsuMtxCreateTimer = setInterval(saveJohaisetsuMtxs, state.johaisetsuMtxCreateTimerSec * 1000);
    };
    const startMoving = async(report: JohaisetsuReport, car: JohaisetsuCar, selectedJohaisetsuType: string) => {
      if (!report?.id) {
        showErrorMsg('走行開始に失敗しました。再度操作を行ってください');
        return;
      }
      state.isRequesting = true;
      try {
        clearTimers();
        state.status = STATUS_RUNNING;
        state.johaisetsuCar.id = car.id;
        state.johaisetsuCar.deviceId = car.device_id;
        state.selectedJohaisetsuType = selectedJohaisetsuType;
        if (report) {
          setJohaisetsuReport(report);
        }
        await restartJohaisetsuCarUpdateInterval();
        restartJohaisetsuMtxUpdateInterval();
        doSpeech('走行を開始します');
        state.isMoving = true;
      } catch (e) {
        console.error('error', e);
        showErrorMsg('走行開始に失敗しました。再度操作を行ってください');
      } finally {
        state.isRequesting = false;
      }
    };
    const startWorking = async() => {
      if (!state.johaisetsuCar.id) return;
      state.isRequesting = true;
      const isResumingWork = state.isSuspending;
      const opType = isResumingWork ? '再開' : '開始';
      try {
        state.workStartTime = new Date();
        state.status = STATUS_WORKING;
        await updateJohaisetsuCar();
        if (!isResumingWork) {
          const data = await updateJohaisetsuStart(state.johaisetsuCar.id, state.workStartTime);
          if (data) {
            setJohaisetsuReport(data);
          }
        }
        state.isRequesting = false;
        restartWorkElapsedTimeNotifyInterval();
        doSpeech(`作業を${opType}します`);
        state.isSuspending = false;
        state.isWorking = true;
      } catch (e) {
        state.isRequesting = false;
        showErrorMsg(`作業${opType}に失敗しました。再度操作を行ってください。`);
      }
    };
    const stopWorking = async() => {
      if (!state.johaisetsuCar.id) return;
      state.isRequesting = true;
      try {
        state.workEndTime = new Date();
        const data = await updateJohaisetsuEnd(state.johaisetsuCar.id, state.workEndTime);
        if (data) {
          setJohaisetsuReport(data);
        }
        await doStopWorking();
        state.isBacking = true;
        doSpeech('作業を終了します');
      } catch (e) {
        showErrorMsg('作業終了に失敗しました。再度操作を行ってください。');
      } finally {
        state.isRequesting = false;
      }
    };
    const baseArrival = async() => {
      state.isRequesting = true;
      try {
        await stopAll();
        doSpeech('基地に到着します');
        state.isBacking = false;
        initJohaisetsuReport();
      } catch (e) {
        showErrorMsg('基地到着に失敗しました。再度操作を行ってください。');
      } finally {
        state.isRequesting = false;
        state.isMoving = false;
        state.isWorking = false;
        state.isSuspending = false;
      }
    };
    const temporarilyStopWorking = async() => {
      state.isRequesting = true;
      try {
        await suspendWork();
        state.isRequesting = false;
        doSpeech('作業を一時停止します');
      } catch (e) {
        state.isRequesting = false;
        showErrorMsg('作業一時停止に失敗しました。再度操作を行ってください。');
      }
    };
    const editContentAndRoad = () => {
      state.isWorkSituationEditing = true;
    };
    const doStopWorking = async() => {
      state.status = STATUS_RUNNING;
      await Promise.all([
        saveJohaisetsuMtxs(),
        updateJohaisetsuCar(),
      ]);
      state.isSuspending = false;
      state.isWorking = false;
    };
    const doStopMoving = async() => {
      state.status = STATUS_STOPPED;
      await updateJohaisetsuCar();
      state.isMoving = false;
    };
    const stopAll = async() => {
      clearTimers();
      await doStopWorking();
      await doStopMoving();
    };
    const suspendWork = async() => {
      if (state.workElapsedTimeNotifyTimer) {
        clearInterval(state.workElapsedTimeNotifyTimer);
      }
      if (state.johaisetsuMtxStoreTimer) {
        clearInterval(state.johaisetsuMtxStoreTimer);
      }
      if (state.johaisetsuMtxCreateTimer) {
        clearInterval(state.johaisetsuMtxCreateTimer);
      }
      state.workElapsedTimeNotifyTimer = null;
      state.johaisetsuMtxStoreTimer = null;
      state.johaisetsuMtxCreateTimer = null;

      await doStopWorking();
      state.isSuspending = true;
    };
    const setMyJohaisetsuCarOnMounted = async(johaisetsuCar: JohaisetsuCarShowResponse) => {
      state.johaisetsuCar.id = johaisetsuCar.id;
      state.johaisetsuCar.deviceId = johaisetsuCar.device_id;
      state.status = johaisetsuCar.status ?? STATUS_STOPPED;
      const { data: report } = await johaisetsuReportApi.getCurrent({
        johaisetsu_car_id: johaisetsuCar.id,
      });
      if (!report.id) return;
      setJohaisetsuReport(report);
      if (!state.johaisetsuCar.report) return;
      const currentReport = state.johaisetsuCar.report;
      restartJohaisetsuCarUpdateInterval();
      restartJohaisetsuMtxUpdateInterval();
      // 現在進行中の報告書がある場合はその情報をもとに画面を復旧させる
      if (currentReport.baseArrivalTs) {
        state.isMoving = false; // 最初からfalseだが一応
        return;
      }
      state.isMoving = true; // フラグが排他じゃないのが困るが、trueにしないといけないらしい
      state.status = STATUS_RUNNING;
      if (currentReport.endTs) {
        state.isBacking = true;
        return;
      }
      if (currentReport.startTs) {
        if (johaisetsuCar.status === STATUS_RUNNING) { //　一時停止中
          state.isSuspending = true;
        } else {
          state.isWorking = true;
          state.status = STATUS_WORKING;
          restartWorkElapsedTimeNotifyInterval();
        }
      }
    };
    const tryRevertEditContentAndRoad = () => {
      state.isWorkSituationEditing = false;
    };
    const logout = async() => {
      await stopAll();
      await store.dispatch(UserActionTypes.LOGOUT);
      // want to explicitly reload
      location.href = '/login';
    };

    const { route } = useRoute();
    const isAvailableJohaisetsuType = (johaisetsuType: string | null): boolean => {
      return !!johaisetsuType && state.johaisetsuTypes.some(x => x.key === johaisetsuType);
    };
    onMounted(async() => {
      const promiseResults = await Promise.all([
        waitForUserAndMasters(),
        waitForJohaisetsuMasters(),
        johaisetsuCarApi.getMyJohaisetsuCar(),
      ]);
      if (!abilityMap.value[props.ability]) {
        location.href = '/';
        return;
      }

      const envElement: HTMLMetaElement | null = document.querySelector("meta[name='viewport']");
      if (envElement) {
        envElement.setAttribute(
          'content',
          'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        );
      }
      enableNoSleep();
      startGettingCurrentLocation();

      state.johaisetsuTypeMap = window.johaisetsuMaster.johaisetsuTypeMap;
      state.johaisetsuTypes = window.johaisetsuMaster.johaisetsuTypes.filter(e => {
        return e.key.endsWith(props.johaisetsuTypeGroup);
      });
      const johaisetsuTypeObj = state.johaisetsuTypes[0];
      if (johaisetsuTypeObj) {
        state.selectedJohaisetsuType = johaisetsuTypeObj.key;
      }
      try {
        const localStorageKey = route.value.name;
        if (localStorageKey) {
          const obj = JSON.parse(localStorage.getItem(localStorageKey) || '');
          if (isAvailableJohaisetsuType(obj.selectedJohaisetsuType)) {
            state.selectedJohaisetsuType = obj.selectedJohaisetsuType;
          }
        }
      } catch (e) {}
      const myJohaisetsuCar = promiseResults[2];
      if (myJohaisetsuCar?.id) {
        await setMyJohaisetsuCarOnMounted(myJohaisetsuCar);
        if (isAvailableJohaisetsuType(myJohaisetsuCar.johaisetsu_type)) {
          state.selectedJohaisetsuType = myJohaisetsuCar.johaisetsu_type;
        }
      }
      state.isReady = true;
      state.displayJohaisetsuHan = johaisetsuHan.value;
    });
    onBeforeUnmount(() => {
      clearTimers();
      clearGeolocationWatch();
      disableNoSleep();
    });

    return {
      state,
      speechSynthesisVoices,
      // computed
      abilityMap,
      currentComponent,
      displayName,
      hasError,
      isGettingCurrentLocation,
      workElapsedTimeInt,
      showWorkTime,
      // methods
      baseArrival,
      clearGeolocationErrors,
      clearGeolocationWatch,
      editContentAndRoad,
      onGeolocationError,
      saveContentAndRoad,
      startMoving,
      startWorking,
      stopWorking,
      showErrorMsg,
      temporarilyStopWorking,
      tryRevertEditContentAndRoad,
      logout,
      dtFormat,
      timeInteger,
    };
  },
});
