import { JohaisetsuReport } from '@/models/apis/johaisetsu/johaisetsuReportResponse';
import {
  JohaisetsuCarReportExt,
  SettouSagyouCommonState,
} from '@/models/johaisetsu/spJohaisetsuReport';
import {
  initJohaisetsuReportCommonState,
  initJohaisetsuCarReportExt,
  toJohaisetsuReportCommonExt,
  initJohaisetsuReportCommonExt,
} from '@/lib/johaisetsu/johaisetsuReportUtil';

export const initJohaisetsuReportState = (): SettouSagyouCommonState => {
  return {
    ...initJohaisetsuReportCommonState(),

    johaisetsuCar: {
      id: null,
      deviceId: null,
      report: {
        ...initJohaisetsuCarReportExt(),
        sanpuNum: null,
        bikou1: null,
      },
    },

    johaisetsuMtxs: [],
    lastJohaisetsuMtxTs: null,

    johaisetsuMtxStoreTimer: null,
    johaisetsuMtxCreateTimer: null,

    johaisetsuMtxStoreTimerSec: 1, // seconds
    johaisetsuMtxCreateTimerSec: 60, // seconds
  };
};

export const initJohaisetsuReportExt = (): JohaisetsuCarReportExt => {
  return {
    ...initJohaisetsuReportCommonExt(),
    sanpuNum: null,
    bikou1: null,
  };
};

export const toJohaisetsuReportExt = (johaisetsuReport: JohaisetsuReport): JohaisetsuCarReportExt => {
  return {
    ...toJohaisetsuReportCommonExt(johaisetsuReport),
    sanpuNum: johaisetsuReport.data_value, // data_value を sanpuNum にマッピング
    bikou1: johaisetsuReport.bikou1,
  };
};
