import { MapViewState, PaneState, PaneStyle } from '@/models/johaisetsu/johaisetsuMap';
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  Ref,
  ref,
  UnwrapRef,
} from '@vue/composition-api';
import {
  LIST_HARD_MAX_HEIGHT,
  PANE_SIDE_HARD_MAX_WIDTH,
  PANE_SIDE_MAX_WIDTH_RATE,
  PANE_SIDE_MIN_WIDTH_RATE,
  VERTICAL_PANE_PADDING_NUMBER,
} from '@/components/Johaisetsu/JohaisetsuMap/consts/johaisetsu_map';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsuHelper';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import { useStore } from '@/hooks/useStore';
import { Settings as UserSettings } from '@/models/apis/user/userResponse';
import { JohaisetsuCarExt } from '@/models/johaisetsu/johaisetsuCar';
import { JohaisetsuMtxExt } from '@/models/apis/johaisetsu/johaisetsuMtxsRequest';
import { JohaisetsuReportExt } from '@/models/apis/johaisetsu/johaisetsuReportResponse';
import { useRoute } from '@/hooks/useRoute';

interface RedrawCarLayerOptions {
  fitToExtent?: boolean;
  setSelectedCarToCenter?: boolean;
}
export interface UseExtremeMapResult {
  extremeMapRef: Ref;
  mapViewState: UnwrapRef<MapViewState>;
  paneStyle: PaneStyle;
  loadExtremeMapEssentials: () => Promise<void>;
  forceResizeExtremeMap: () => void;
  redrawCarLayer: (options: RedrawCarLayerOptions) => void;
  showJohaisetsuReportLayer: (selectedReport: JohaisetsuReportExt, fitToExtent: boolean) => void;
  removeJohaisetsuReportLayer: () => void;
  showJohaisetsuCarPopup: (car: JohaisetsuCarExt) => void;
  showJohaisetsuMtxPopup: (mtx: JohaisetsuMtxExt) => void;
  showSettouPatrolReportDetailPage: (report: JohaisetsuReportExt) => void;
  hidePopup: () => void;
}

export function useExtremeMap(paneState: PaneState): UnwrapRef<UseExtremeMapResult> {
  const store = useStore();
  const userState = store.state.user;
  const userSettings = computed<UserSettings>(() => {
    return userState.settings;
  });

  const extremeMapRef = ref<InstanceType<typeof ExtremeMap>>();

  const paneStyle = reactive<PaneStyle>({
    paneSideMinWidth: PANE_SIDE_HARD_MAX_WIDTH,
    paneSideMaxWidth: PANE_SIDE_HARD_MAX_WIDTH,
    listMinHeight: LIST_HARD_MAX_HEIGHT,
    listMaxHeight: LIST_HARD_MAX_HEIGHT,
  });

  const mapViewState = reactive<MapViewState>({
    extremeMapEssentials: null,
    onResizeFunc: () => {},
  });

  const loadExtremeMapEssentials = async(): Promise<void> => {
    await Promise.all([waitForUserAndMasters(), waitForJohaisetsuMasters()]);
    mapViewState.extremeMapEssentials = {
      userSettings: userSettings.value,
      kpMap: window.master.kpMap,
    };
  };

  const resizeMap = (mapHeight: number) => {
    if (!extremeMapRef.value) return;

    extremeMapRef.value.setMapHeight(mapHeight);
    extremeMapRef.value.triggerResize();
  };

  const initResizeFunc = () => {
    mapViewState.onResizeFunc = () => {
      const headerH = 57;
      const searchBarH = 96;
      const h = window.innerHeight - headerH - searchBarH;
      const w = window.innerWidth - VERTICAL_PANE_PADDING_NUMBER;
      paneStyle.paneSideMinWidth = Math.min(Math.floor(w * PANE_SIDE_MIN_WIDTH_RATE), PANE_SIDE_HARD_MAX_WIDTH);
      paneStyle.paneSideMaxWidth = Math.min(Math.floor(w * PANE_SIDE_MAX_WIDTH_RATE), PANE_SIDE_HARD_MAX_WIDTH);
      paneStyle.listMinHeight = parseInt((h * (paneState.paneStyleLimitMap?.listMinHeightRate || 1.0)).toString());
      paneStyle.listMaxHeight = parseInt((h * (paneState.paneStyleLimitMap?.listMaxHeightRate || 1.0)).toString());
      resizeMap(h);
    };
    mapViewState.onResizeFunc();
    window.addEventListener('resize', mapViewState.onResizeFunc);
  };

  const showJohaisetsuReportLayer = (selectedReport: JohaisetsuReportExt, fitToExtent: boolean): void => {
    if (!extremeMapRef.value) return;

    extremeMapRef.value.showJohaisetsuReportLayer(selectedReport, fitToExtent);
  };

  const forceResizeExtremeMap = (): void => {
    mapViewState.onResizeFunc();
  };

  const redrawCarLayer = ({ fitToExtent }: RedrawCarLayerOptions = {}) => {
    if (!extremeMapRef.value) return;

    extremeMapRef.value.redrawCarLayer({
      hideCarIcons: !paneState.showCarIcons,
      fitToExtent: fitToExtent || false,
    });
  };

  const removeJohaisetsuReportLayer = (): void => {
    if (!extremeMapRef.value) return;
    extremeMapRef.value.removeJohaisetsuReportLayer();
  };

  const showJohaisetsuCarPopup = (car: JohaisetsuCarExt): void => {
    if (!extremeMapRef.value) return;
    extremeMapRef.value.showJohaisetsuCarPopup(car);
  };

  const showJohaisetsuMtxPopup = (mtx: JohaisetsuMtxExt): void => {
    if (!extremeMapRef.value) return;
    extremeMapRef.value.showJohaisetsuMtxPopup(mtx);
  };

  const hidePopup = (): void => {
    if (!extremeMapRef.value) return;
    extremeMapRef.value.hidePopup();
  };
  const { router } = useRoute();
  const showSettouPatrolReportDetailPage = (report: JohaisetsuReportExt) => {
    const routeObj = {
      name: 'SettouPatrolReportDetail',
      params: {
        id: report.id.toString(),
      },
    };
    const obj = router.resolve(routeObj);
    window.open(obj.href, '_blank');
  };

  onMounted(async() => {
    initResizeFunc();
  });

  onUnmounted(() => {
    window.removeEventListener('resize', mapViewState.onResizeFunc);
  });

  return {
    extremeMapRef,
    mapViewState,
    paneStyle,
    loadExtremeMapEssentials,
    forceResizeExtremeMap,
    redrawCarLayer,
    removeJohaisetsuReportLayer,
    showJohaisetsuReportLayer,
    showJohaisetsuCarPopup,
    showJohaisetsuMtxPopup,
    showSettouPatrolReportDetailPage,
    hidePopup,
  };
}
