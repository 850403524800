


import {
  defineComponent,
  reactive,
} from '@vue/composition-api';
import useSettouSagyou from '@/components/Sp/SettouSagyouReport/composables/useSettouSagyou';

interface State {
  showConfirmStartWorkingModal: boolean;
  showConfirmStopWorkingModal: boolean;
}

export default defineComponent({
  name: 'sp-settou-sagyou-pause',
  props: {
    selectedJohaisetsuType: {
      type: String,
      required: true,
    },
    isRequesting: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      showConfirmStartWorkingModal: false,
      showConfirmStopWorkingModal: false,
    });
    const {
      johaisetsuTypeGroupName,
      johaisetsuTypeNameDisp,
    } = useSettouSagyou({ selectedJohaisetsuType: props.selectedJohaisetsuType });
    const editContentAndRoad = () => {
      emit('editContentAndRoadOnCommon');
    };
    const tryShowConfirmStartWorkingModal = () => {
      state.showConfirmStartWorkingModal = true;
    };
    const startWorking = () => {
      state.showConfirmStartWorkingModal = false;
      emit('startWorkingOnCommon');
    };
    const showConfirmStopWorkingModal = () => {
      state.showConfirmStopWorkingModal = true;
    };
    const stopWorking = () => {
      state.showConfirmStopWorkingModal = false;
      emit('stopWorkingOnCommon');
    };
    return {
      state,
      johaisetsuTypeGroupName,
      johaisetsuTypeNameDisp,
      editContentAndRoad,
      tryShowConfirmStartWorkingModal,
      startWorking,
      showConfirmStopWorkingModal,
      stopWorking,
    };
  },
});
